import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

import { ReactComponent as PersonIcon } from 'assets/svgs/PersonIdentityIcon.svg';
import AgentPills, { PillTypes } from 'components/shared/AgentPills/AgentPills';
import RCButton from 'components/shared/Button/Button';
import { RenterResumeNavbarKeys } from 'constants/agentConstants';
import { FilterOptions } from 'constants/organizationConstants';
import { formatDateToSubmitFormat } from 'helpers/applicantsHelper';
import { IsMobileApplicantsPage } from 'helpers/IsMobile';
import { routes } from 'shared/routes';
import { PropertyApplicationStatus } from 'shared/types/agentTypes';
import { ApplicantInformationProps } from 'shared/types/applicantsType';
import { RenterRoles } from 'shared/types/renterTypes';

import { ApplicationTooltip } from '../ApplicationTooltip/ApplicationTooltip';
import { AddressCapsule } from '../PropertyCapsules/AddressCapsule/AddressCapsule';
import { CreditBackgroundCapsule } from '../PropertyCapsules/CreditBackgroundCapsule/CreditBackgroundCapsule';
import { IncomeCapsule } from '../PropertyCapsules/IncomeCapsule/IncomeCapsule';

import styles from './ApplicantInformation.module.scss';
interface IDVPillType {
  text: string;
  type: PillTypes;
}
export const ApplicantInformation = ({
  isLast = false,
  applicationInformation,
  occupantNumber,
  applicationStatus,
  propertyId,
  onIncomeClickHandler,
}: ApplicantInformationProps): JSX.Element => {
  const [serachParams] = useSearchParams();
  const activeOption = serachParams.get('option');
  const { renterFirstName, renterLastName, renterRole, id: applicationId, stripeVerified } = applicationInformation;
  const isMobileView = IsMobileApplicantsPage();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isShowingOverlay, setIsShowingOverlay] = useState(false);
  const getPillType = (): PillTypes => {
    if (applicationStatus === PropertyApplicationStatus.COMPLETED) {
      return PillTypes.SUCCESS;
    } else if (applicationStatus === PropertyApplicationStatus.IN_PROGRESS) {
      return PillTypes.WARNING_DARK;
    }

    return PillTypes.SECONDARY;
  };
  const getIDVPill = useMemo((): IDVPillType => {
    if (stripeVerified) {
      return { text: t('application.idvPassed'), type: PillTypes.SUCCESS };
    }

    return { text: t('application.idvUnverified'), type: PillTypes.SECONDARY };
  }, [stripeVerified, t]);
  const onRenterResumeSelect = (id: number): void => {
    navigate(routes.generateRenterResume(id, Number(propertyId)));
  };
  const onRenterCreditBackgroundSelect = (id: number): void => {
    navigate(routes.generateRenterResumeNav(id, Number(propertyId), RenterResumeNavbarKeys.CREDIT_AND_BACKGROUND));
  };

  return (
    <div className={classNames(styles.Container, { [styles.LastApplication]: isLast })}>
      <div className={styles.Header}>
        {activeOption === FilterOptions.LEADS && (
          <AgentPills
            pillContainerClassName={styles.StatusPillContainer}
            pillsData={[{ isItalic: true, heading: getIDVPill.text, type: getIDVPill.type }]}
          />
        )}
        <AgentPills
          pillContainerClassName={styles.StatusPillContainer}
          pillsData={[{ isItalic: true, heading: applicationStatus, type: getPillType() }]}
        />
        <div className={styles.UpdatedDate}>
          {formatDateToSubmitFormat(
            applicationInformation.confirmedAt ||
              applicationInformation.submittedAt ||
              applicationInformation.updatedAt ||
              applicationInformation.createdAt
          )}
        </div>
      </div>
      <div className={styles.InformationContainer}>
        <div className={styles.ApplicantName}>
          <div className={styles.ApplicantInformation}>
            {renterFirstName || renterLastName ? (
              <RCButton
                onClick={() => onRenterResumeSelect(Number(applicationInformation.id))}
                className={classNames(styles.Name, styles.NavigationButton)}
              >{`${renterFirstName ?? ''} ${renterLastName ?? ''}`}</RCButton>
            ) : (
              <div className={styles.NoExistence}>____</div>
            )}
            {isMobileView && applicationInformation.renterRole === RenterRoles.PRIMARY && (
              <div className={styles.OptionsContainer}>
                <PersonIcon width={16} height={16} className={styles.PersonIcon} />
                <RCButton onBlur={() => setIsShowingOverlay(false)} className={styles.IconButton}>
                  <ApplicationTooltip
                    setIsShowingOverlay={setIsShowingOverlay}
                    isShowingOverlay={isShowingOverlay}
                    onRenterResumeSelect={() => onRenterResumeSelect(Number(applicationInformation.id))}
                    isShowRenterResume={true}
                  />
                </RCButton>
              </div>
            )}
          </div>
          <div className={styles.Role}>
            {renterRole}
            {renterRole === RenterRoles.OCCUPANTS && <span className={styles.Count}>{occupantNumber}</span>}
          </div>
        </div>
        <div className={classNames(styles.CapsulesContainer, styles.CreditBackgroundCapsule)}>
          <CreditBackgroundCapsule
            experianAuth={applicationInformation.experianAuth}
            creditScore={applicationInformation.creditScore}
            novaAuth={applicationInformation.novaAuth}
            creditCheckProvider={applicationInformation.creditCheckProvider}
            novaCreditScore={applicationInformation.novaCreditScore}
            onCustomClick={() => onRenterCreditBackgroundSelect(Number(applicationInformation.id))}
            applicationStatus={applicationStatus}
          />
        </div>
        <div className={classNames(styles.CapsulesContainer, styles.AddressCapsule)}>
          <AddressCapsule
            hasPresentAddress={applicationInformation.hasPresentAddress}
            hasPriorAddress={applicationInformation.hasPriorAddress}
            inProgressAddressVerificationCount={applicationInformation.inProgressAddressVerificationCount}
            verifiedAddressVerificationCount={applicationInformation.verifiedAddressVerificationCount}
            warningAddressVerificationCount={applicationInformation.warningAddressVerificationCount}
          />
        </div>
        <div className={classNames(styles.CapsulesContainer, styles.IncomeCapsule)}>
          <IncomeCapsule
            onIncomeClickHandler={() =>
              onIncomeClickHandler(applicationInformation.renterProfileId ?? 0, applicationStatus, applicationId ?? -1)
            }
            income={applicationInformation.income}
          />
        </div>
      </div>
    </div>
  );
};
