/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import classNames from 'classnames';
import { saveAs } from 'file-saver';
import { isEmpty } from 'lodash-es';
import Papa from 'papaparse';
import {
  selectAgentProfileInformation,
  selectCurrentProperty,
  selectedRenterDetails,
  selectRenterApplication,
  selectRenterCreditProfile,
  selectRenterCriminalReport,
  selectRenterEvictionReport,
  selectVerifierAddressInformation,
  selectVerifierEmploymentInformation,
} from 'redux/selectors/agentSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { selectPropertyAdditionalInfo } from 'redux/selectors/renterSelector';
import {
  getAddressVerificationInformation,
  getEmploymentVerificationInformation,
  getRenterExperianReport,
  getVerifierAddresses,
  getVerifierEmployments,
} from 'redux/slices/agentSlice';
import { getAdditionalApplicationInfo } from 'redux/slices/renterSlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as ArrowLeft } from 'assets/svgs/ArrowLeftDirected.svg';
import ActionDropDown from 'components/Agent/components/shared/ActionDropDown/ActionDropDown';
import { ApplicationActionOptions } from 'constants/dropdownOptions';
import { ExperianAuthenticationStatus, RenterAddressTypes } from 'constants/renterConstants';
import { formatDateTime } from 'helpers/agentHelper';
import { toTitleCase } from 'helpers/helper';
import { formatSSN } from 'helpers/renterHelper';
import { retrieveRenterTransactionReport } from 'services/agentService';
import { Colors } from 'shared/constants';
import { RenterResumeV2 } from 'shared/pdf/RenterResumePDF_V2';
import { routes } from 'shared/routes';
import { ApplicationActions, VerifierAddressObject, VerifierEmploymentObject } from 'shared/types/agentTypes';
import { ApplicationAdditionalInfoType, RenterRoles } from 'shared/types/renterTypes';
import { FinancialData } from 'shared/types/reportTypes';

import { ExportModal } from '../../RenterResume/ExportModal/ExportModal';

import styles from '../AgentDetailsForResume.module.scss';

type AgentHeadingProps = {
  onStatusClickHandler: (status: ApplicationActions) => void;
};

const AgentHeading = ({ onStatusClickHandler }: AgentHeadingProps): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { applicationId, propertyId } = useParams();
  const dispatch = useDispatch<AppThunkDispatch>();
  const renterDetails = useSelector(selectedRenterDetails);
  const agentDetails = useSelector(selectAgentProfileInformation);
  const application = useSelector(selectRenterApplication);
  const creditReport = useSelector(selectRenterCreditProfile);
  const currentOrganization = useSelector(selectedOrganization);
  const [isReportLoading, setIsReportLoading] = useState(false);
  const selectedProperty = useSelector(selectCurrentProperty);
  const housingRecordsInformation = useSelector(selectRenterEvictionReport);
  const [hasAddressVerifier, setHasAddressVerifier] = useState(false);
  const [hasEmploymentVerifier, setHasEmploymentVerifier] = useState(false);
  const [areTransactionsFetched, setAreTransactionsFetched] = useState(false);
  const employmentVerificationInformation = useSelector(selectVerifierEmploymentInformation);
  const addressVerificationInformation = useSelector(selectVerifierAddressInformation);
  const [verifierAddresses, setVerifierAddresses] = useState<VerifierAddressObject[]>();
  const [verifierEmployments, setVerifierEmployments] = useState<VerifierEmploymentObject[]>();
  const criminalReport = useSelector(selectRenterCriminalReport);
  const isApiRequested = useRef(false);
  const [transactionalInformation, setTransactionalInformation] = useState<FinancialData>({} as FinancialData);
  const propertyAdditionalInfo = useSelector(selectPropertyAdditionalInfo);
  const [additionalInfo, setAdditionalInfo] = useState({} as ApplicationAdditionalInfoType);
  const callingAPIs = async (): Promise<void> => {
    setIsReportLoading(true);
    isApiRequested.current = true;
    Promise.allSettled([
      application.isConfirmed
        ? dispatch(
            getRenterExperianReport({
              organizationId: currentOrganization.id,
              applicationId: Number(applicationId),
              isForPDF: true,
            })
          )
        : Promise.resolve({}),
      renterDetails?.userId
        ? retrieveRenterTransactionReport({
            organizationId: currentOrganization.id,
            renterId: renterDetails.userId,
            applicationId: Number(applicationId),
          })
        : Promise.resolve(null),

      Promise.allSettled([
        currentOrganization.id && renterDetails.userId
          ? dispatch(getVerifierAddresses({ organizationId: currentOrganization.id, renterId: renterDetails.userId }))
          : Promise.resolve([]),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ]).then((res: any) => {
        if (res?.[0]?.value?.payload?.length > 0) {
          setHasAddressVerifier(true);
          setVerifierAddresses(res?.[0].value.payload);
        }

        res?.[0]?.value?.payload?.map((verifierAddress: VerifierAddressObject) => {
          dispatch(
            getAddressVerificationInformation({
              organizationId: currentOrganization.id,
              renterId: renterDetails.userId,
              addressId: verifierAddress.id,
            })
          );
        });
      }),
      Promise.allSettled([
        currentOrganization.id && renterDetails.userId
          ? dispatch(getVerifierEmployments({ organizationId: currentOrganization.id, renterId: renterDetails.userId }))
          : Promise.resolve([]),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ]).then((res: any) => {
        if (res?.[0]?.value?.payload?.length > 0) {
          setHasEmploymentVerifier(true);
          setVerifierEmployments(res?.[0]?.value?.payload);
        }

        res?.[0]?.value?.payload?.map((verifierEmployment: VerifierEmploymentObject) => {
          dispatch(
            getEmploymentVerificationInformation({
              organizationId: currentOrganization.id,
              renterId: renterDetails.userId,
              employmentId: verifierEmployment.id,
            })
          );
        });
      }),

      dispatch(
        getAdditionalApplicationInfo({
          propertyId: Number(propertyId),
          applicationId: Number(applicationId),
          organizationId: currentOrganization.id,
        })
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ]).then(async (result: any) => {
      if (result?.[1]?.status === 'fulfilled') {
        setAreTransactionsFetched(true);
        setTransactionalInformation(result?.[1]?.value?.data?.payload);
      }

      if (result?.[4].status === 'fulfilled') {
        setAdditionalInfo(result?.[4].value?.payload);
      }

      setIsReportLoading(false);
    });
  };
  const generatePdfDocument = (): void => {
    if (currentOrganization.id && applicationId && !isReportLoading) {
      callingAPIs();
    }
  };
  const exportToCSV = (): void => {
    if (currentOrganization.id && applicationId && !isReportLoading) {
      callingAPIs();
    }

    const csvData = [
      {
        Property: `${selectedProperty.streetAddress1} ${selectedProperty.city} ${selectedProperty.state} ${selectedProperty.zipCode} `,
        Submitted_On: formatDateTime(application.submittedAt),
        Desired_Move_In_Date: propertyAdditionalInfo?.desiredMoveInDate,
        Role: application.renterRole === RenterRoles.PRIMARY ? 'Occupant' : 'Guarantor',
        Total_Occupants: (application?.adults?.length ?? 0) + (application?.minors?.length ?? 0) + 1,
        Lease_Terms: [''],
        Rent_Amount: selectedProperty.monthlyRentAmount,
        Full_Name: `${renterDetails.firstName}  ${renterDetails.lastName}`,
        Identity: renterDetails.stripeVerified,
        Phone_Type_1: renterDetails?.renterPhoneNumbers && renterDetails?.renterPhoneNumbers[0]?.type,
        Phone_1: renterDetails?.renterPhoneNumbers && renterDetails?.renterPhoneNumbers[0]?.phoneNumber,
        Phone_Type_2: (renterDetails?.renterPhoneNumbers && renterDetails?.renterPhoneNumbers[1]?.type) || 'N/A',
        Phone_2: (renterDetails?.renterPhoneNumbers && renterDetails?.renterPhoneNumbers[1]?.phoneNumber) || 'N/A',
        Phone_Type_3: (renterDetails?.renterPhoneNumbers && renterDetails?.renterPhoneNumbers[2]?.type) || 'N/A',
        Phone_3: (renterDetails?.renterPhoneNumbers && renterDetails?.renterPhoneNumbers[1]?.phoneNumber) || 'N/A',
        Email: renterDetails.email,
        Present_Address_Type:
          renterDetails?.addresses && renterDetails.addresses[0]?.addressType === RenterAddressTypes.PRIOR
            ? t('renter.ConfirmPresentAddress.iRented')
            : t('renter.ConfirmPresentAddress.iRent') || 'N/A',
        Present_Address: `${renterDetails?.addresses && renterDetails.addresses[0]?.streetAddress} ${
          renterDetails?.addresses && renterDetails.addresses[0]?.city
        } ${renterDetails?.addresses && renterDetails.addresses[0]?.state} ${
          renterDetails?.addresses && renterDetails.addresses[0]?.zipCode
        }`,
        Present_Address_Start: renterDetails?.addresses && renterDetails.addresses[0]?.moveInDate,
        Present_VOR_Status: addressVerificationInformation[0]?.verifierStatus,
        Prior_Address_1_Type:
          renterDetails?.addresses && renterDetails.addresses[1]?.addressType === RenterAddressTypes.PRIOR
            ? t('renter.ConfirmPresentAddress.iRented')
            : t('renter.ConfirmPresentAddress.iRent') || 'N/A',
        Prior_Address_1:
          `${renterDetails?.addresses && renterDetails.addresses[1]?.streetAddress} ${
            renterDetails?.addresses && renterDetails.addresses[1]?.city
          } ${renterDetails?.addresses && renterDetails.addresses[1]?.state} ${
            renterDetails?.addresses && renterDetails.addresses[1]?.zipCode
          }` || 'N/A',
        Prior_Address_1_Start_End_Date:
          `${renterDetails?.addresses && renterDetails.addresses[1]?.fromDate} 'to' ${
            renterDetails?.addresses && renterDetails.addresses[1]?.toDate
          }` || 'N/A',
        Prior_1_VOR_Status: addressVerificationInformation[1]?.verifierStatus || 'N/A',
        Income_Type: renterDetails.presentEmployments[0].employmentSubType,
        Stated_Income: propertyAdditionalInfo.groupIncome,
        Animal: propertyAdditionalInfo.animalsCount ? 'Yes' : 'No',
        Score: renterDetails.creditScore ?? 'N/A',
        SNN: formatSSN(renterDetails.ssnTailDigits as string),
        US_Credit: renterDetails.experianAuth === ExperianAuthenticationStatus.COMPLETED ? 'Yes' : 'No',
        International: 'N/A',
        Criminal_Records: criminalReport.success ? 'Yes' : 'No',
        Housing_Court_Records: housingRecordsInformation.success ? 'Yes' : 'No',
      },
      // Add other rows if needed
    ];
    const csv = Papa.unparse(csvData, {
      header: true,
    });
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    saveAs(blob, 'Renter Resume CSV');
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOptionChange = (option: any): void => {
    const { value, label } = option;

    if (value && value !== ApplicationActions.EXPORT) {
      onStatusClickHandler(label);
    }

    if (value === ApplicationActions.EXPORT) {
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    const generatePDF = async (): Promise<void> => {
      isApiRequested.current = false;
      const blob = await pdf(
        <RenterResumeV2
          renterDetails={renterDetails}
          application={application}
          criminalReport={criminalReport}
          housingRecordsInformation={housingRecordsInformation}
          employmentVerificationInformation={employmentVerificationInformation ?? []}
          addressVerificationInformation={addressVerificationInformation ?? []}
          incomeTransactions={transactionalInformation}
          propertyInformation={selectedProperty}
          additionalInfo={additionalInfo}
          agentDetails={agentDetails}
          currentOrganization={currentOrganization}
          verifierAddresses={verifierAddresses ?? []}
          verifierEmployments={verifierEmployments ?? []}
        />
      ).toBlob();

      saveAs(blob, 'Renter Resume');
    };

    if (
      isApiRequested.current &&
      ((hasAddressVerifier && !isEmpty(verifierAddresses) && !isEmpty(addressVerificationInformation)) ||
        !hasAddressVerifier) &&
      ((hasEmploymentVerifier && !isEmpty(verifierEmployments) && !isEmpty(employmentVerificationInformation)) ||
        !hasEmploymentVerifier) &&
      !isReportLoading &&
      ((areTransactionsFetched && !!transactionalInformation?.incomeSummary?.averageMonthly) ||
        !areTransactionsFetched) &&
      additionalInfo?.desiredMoveInDate
    ) {
      setAreTransactionsFetched(false);
      setHasAddressVerifier(false);
      setHasEmploymentVerifier(false);
      generatePDF();
    }
  }, [
    addressVerificationInformation,
    application,
    areTransactionsFetched,
    creditReport,
    criminalReport,
    employmentVerificationInformation,
    hasAddressVerifier,
    hasEmploymentVerifier,
    housingRecordsInformation,
    isReportLoading,
    renterDetails,
    selectedProperty,
    transactionalInformation,
    additionalInfo,
    agentDetails,
    currentOrganization,
    verifierAddresses,
    verifierEmployments,
  ]);

  return (
    <>
      <Row className={styles.headingDiv}>
        <Col className={styles.mainContainer}>
          <ArrowLeft
            className={styles.arrowContainer}
            color={Colors.COLOR_SVG_GRAY_LIGHT}
            onClick={() => navigate(routes.applicants)}
          />
          <h1 className={styles.statusDiv}>{toTitleCase(application?.fileApplication?.status)}</h1>
          <h2 className={classNames(styles.LabelDiv, styles.normalFontWeight)}>
            {t('renter.application.completeDate')}
          </h2>
          <span className={styles.LabelDiv}>
            {formatDateTime(application?.fileApplication?.updatedAt || new Date().toDateString())}
          </span>
        </Col>
        <Col className={styles.headingDiv}>
          <ActionDropDown
            status={application?.fileApplication?.status}
            dropDownOptions={ApplicationActionOptions}
            handleOptionChange={handleOptionChange}
          />
        </Col>
      </Row>
      {isModalOpen && (
        <ExportModal
          setIsModalOpen={setIsModalOpen}
          generatePdfDocument={generatePdfDocument}
          exportToCSV={exportToCSV}
        />
      )}
    </>
  );
};

export default AgentHeading;
