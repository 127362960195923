import { AxiosError } from 'axios';
import { differenceInMonths, differenceInYears, parseISO } from 'date-fns';
import { t } from 'i18next';
import JSEncrypt from 'jsencrypt';
import { setServerError } from 'redux/slices/authSlice';

import { StatusCodes } from 'shared/constants';
import { AddressVerificationResponse, DefaultErrorResponse, ErrorResponse } from 'shared/types/agentTypes';
import { HandleServerResponseErrorProps } from 'shared/types/sharedTypes';
export const handleServerResponseError = ({ error, dispatch }: HandleServerResponseErrorProps): void => {
  if (error instanceof AxiosError && error.response) {
    if (
      error.response?.status === StatusCodes.NOT_FOUND ||
      error.response?.status === StatusCodes.INTERNAL_SERVER_ERROR
    ) {
      const status = error.response?.status;

      dispatch(setServerError(status));
    }
  }
};
export const formatName = (firstName?: string, middleName?: string, lastName?: string): string => {
  if (!firstName && !middleName && !lastName) {
    return '';
  }

  return `${firstName ?? ''} ${middleName ?? ''} ${lastName ?? ''}`;
};

export const formatAmountToUsdString = (amount: number): string =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(Number(amount));

export const getDateDifferenceFormattedString = (date: string | Date): string => {
  const pastDate = new Date(parseISO(date.toString()));
  const now = new Date();
  const diffInYears = differenceInYears(now, pastDate);
  const diffInMonths = differenceInMonths(now, pastDate) % 12;

  return `${diffInYears} yrs, ${diffInMonths} mos`;
};

//For parsing the response Errors
export const checkHasProperty = (obj: unknown, propertyName: string): boolean =>
  Object.prototype.hasOwnProperty.call(obj, propertyName);

const checkDefaultIsError = (error: unknown): error is Error => checkHasProperty(error, 'message');
const checkIfDefaultIsError = (error: unknown): error is DefaultErrorResponse =>
  checkHasProperty(error, 'errorMessage');
const checkIsErrorResponse = (error: unknown): error is ErrorResponse => checkHasProperty(error, 'response');

export const parseResponseErrors = (error: string | ErrorResponse | Error | DefaultErrorResponse | unknown): string => {
  let notificationString = t('renter.PhotoIdInformation.wentWrong');

  if (!error) {
    return notificationString;
  }

  const isDefaultError = checkDefaultIsError(error);
  const isErrorResponse = checkIsErrorResponse(error);
  const isSecondDefaultError = checkIfDefaultIsError(error);

  if (typeof error === 'string') {
    notificationString = error;
  } else if (isDefaultError && !isErrorResponse) {
    notificationString = error.message;
  } else if (isSecondDefaultError) {
    notificationString = typeof error.errorMessage === 'string' ? error.errorMessage : error.errorMessage[0];
  } else if (!isErrorResponse) {
    notificationString = t('renter.customError.serverNotRunning');
  } else if (typeof error.response?.data?.errors?.[0]?.errorMessage === 'string') {
    notificationString = `${error.response.data?.errors?.[0]?.errorOnField || ''}  ${
      error.response.data?.errors?.[0]?.errorMessage
    }`;
  }

  return notificationString;
};
export const encryptData = (data: string): string | undefined => {
  const publicKey: string = process.env.REACT_APP_PUBLIC_ENCRYPTION_KEY || '';
  const encrypt: JSEncrypt = new JSEncrypt();

  encrypt.setPublicKey(publicKey);
  const encrypted: string | false = encrypt.encrypt(data);

  if (encrypted) {
    return encrypted;
  }
};

//Helper for getting unique address verifier array
export const getUniqueAddressVerifiers = (
  addressVerificationResponse: AddressVerificationResponse[]
): AddressVerificationResponse[] => {
  const seenIds = new Set<number>();

  return addressVerificationResponse.filter((item) => {
    if (seenIds.has(item.renterAddressInformation?.addressId || 0)) {
      return false;
    }

    seenIds.add(item.renterAddressInformation?.addressId || 0);

    return true;
  });
};
//Helper for getting unique employment verifier array
export const getUniqueEmploymentVerifiers = (
  employmentVerificationResponse: AddressVerificationResponse[]
): AddressVerificationResponse[] => {
  const seenIds = new Set<number>();

  return employmentVerificationResponse.filter((item) => {
    if (seenIds.has(item.renterEmploymentInformation?.presentEmploymentId || 0)) {
      return false;
    }

    seenIds.add(item.renterEmploymentInformation?.presentEmploymentId || 0);

    return true;
  });
};

export const toTitleCase = (title: string): string => {
  const newTitle = title?.toLowerCase();

  return newTitle?.charAt(0)?.toUpperCase() + newTitle?.slice(1);
};

export const tabTitle = (newTitle: string): string => {
  document.title = `Intellirent | ${newTitle}`;

  return document.title;
};
