import { FunctionComponent, ReactNode, SVGProps } from 'react';

import Modal from 'components/shared/Modal/Modal';

import styles from './AgentModal.module.scss';

type AgentModalProps = {
  show: boolean;
  title?: string;
  onHide: () => void;
  buttonText?: string;
  children: React.ReactElement;
  size?: string;
  crossButtonClassName?: string;
  contentClassName?: string;
  customCrossIcon?: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  customTitleIcon?: ReactNode;
  backDropClassName?: string;
  customTitleClassName?: string;
  customContainerClassName?: string;
  customModalClassName?: string;
  titleDescription?: string;
  applicationStatus?: string;
  customStatusClassName?: string;
  customHeaderClassName?: string;
};

const AgentModal = ({ children, customModalClassName, titleDescription, ...props }: AgentModalProps): JSX.Element => (
  <Modal centered className={customModalClassName} titleDescription={titleDescription} {...props} size="lg">
    <div className={styles.container}>{children}</div>
  </Modal>
);

export default AgentModal;
