import { Dispatch, SetStateAction } from 'react';

import { ExperianAuthenticationStatus } from 'constants/renterConstants';

import { PropertyApplicationStatus } from './agentTypes';

/* eslint-disable @typescript-eslint/naming-convention */
export interface ProfileSummaryResponse {
  DisputedAccountsExcluded: number | null;
  DelinquenciesOver30Days: number | null;
  DelinquenciesOver60Days: number | null;
  DelinquenciesOver90Days: number | null;
  DerogCounter: number | null;
  PublicRecordsCount: number | null;
  TotalInquiries: number | null;
  MonthlyPaymentPartialFlagDesc: string | null;
  InquiriesDuringLast6Months: number | null;
  NowDelinquentDerog: number | null;
  RealEstatePaymentPartialFlagDesc: string | null;
  TotalTradeItems: number | null;
  WasDelinquentDerog: number | null;
  RevolvingAvailablePartialFlagDesc: string | null;
  PaidAccounts: number | null;
  hasProfileSummary: boolean | null;
  InstallmentBalance: string | null;
  MonthlyPayment: string | null;
  PastDueAmount: string | null;
  RealEstateBalance: string | null;
  RealEstatePayment: string | null;
  RevolvingBalance: string | null;
  RevolvingAvailablePercent: string | null;
  OldestTradeOpenDate: string | null;
  Y2KOldestTradeline: string | null;
  SatisfactoryAccount: number | null;
}
export interface PublicRecordItem {
  Status: ReportItemObject;
  StatusDate: string | null;
  FilingDate: string | null;
  Evaluation: ReportItemObject;
  Amount: string | null;
  ConsumerComment: string | null;
  Court: ReportItemObject | null;
  ReferenceNumber: string | null;
  PlaintiffName: string | null;
  DisputeFlag: string | null;
  ECOA: ReportItemObject;
  BookPageSequence: string | null;
  Bankruptcy: PublicRecordBankruptcy;
}
export interface PublicRecordBankruptcy {
  Type: ReportItemObject;
  AssetAmount: string | null;
  LiabilitiesAmount: string | null;
  RepaymentPercent: string | null;
  AdjustmentPercent: string | null;
}
export type ReportItemObject = {
  desc: string | null;
  code: string | null;
};

export interface InquiryReportItem {
  Date: string | null;
  Amount: string | null;
  Type: ReportItemObject;
  Terms: ReportItemObject;
  Subcode: string | null;
  KOB: ReportItemObject;
  SubscriberDisplayName: string | null;
}
export interface TradeLineReportItem {
  SpecialComment: ReportItemObject;
  Evaluation: ReportItemObject;
  OpenDate: string | null;
  StatusDate: string | null;
  MaxDelinquencyDate: string | null;
  AccountType: ReportItemObject;
  TermsDuration: ReportItemObject;
  ECOA: ReportItemObject;
  Amount: TradeLineAmountItem[];
  BalanceDate: string | null;
  BalanceAmount: string | null;
  Status: ReportItemObject;
  AmountPastDue: string | null;
  OpenOrClosed: ReportItemObject;
  RevolvingOrInstallment: ReportItemObject;
  ConsumerComment: string | null;
  AccountNumber: string | null;
  MonthsHistory: string | null;
  DelinquenciesOver30Days: string | null;
  DelinquenciesOver60Days: string | null;
  DelinquenciesOver90Days: string | null;
  DerogCounter: string | null;
  PaymentProfile: string | null;
  MonthlyPaymentAmount: string | null;
  MonthlyPaymentType: string | null;
  LastPaymentDate: string | null;
  Subcode: string | null;
  KOB: ReportItemObject;
  SubscriberDisplayName: string | null;
  EnhancedPaymentData: EnhancedPaymentItem;
  MaxPayment: string | null;
  FirstDelinquencyDate: string | null;
  SecondDelinquencyDate: string | null;
}
export interface EnhancedPaymentItem {
  InitialPaymentLevelDate: string | null;
  AccountCondition: ReportItemObject;
  PaymentStatus: ReportItemObject;
  AccountType: ReportItemObject;
  SpecialComment: ReportItemObject;
}

export interface TradeLineAmountItem {
  Qualifier: ReportItemObject;
  Value: string | null;
}
export interface ConsumerReportNameItem {
  Type: ReportItemObject;
  Surname: string | null;
  First: string | null;
  Middle: string | null;
}
export interface ConsumerReportItem {
  Name: ConsumerReportNameItem;
}

export interface AddressInformationReportItem {
  FirstReportedDate: string | null;
  LastUpdatedDate: string | null;
  Origination: ReportItemObject;
  TimesReported: string | null;
  LastReportingSubcode: string | null;
  DwellingType: ReportItemObject;
  HomeOwnership: ReportItemObject;
  StreetPrefix: string | null;
  StreetName: string | null;
  StreetSuffix: string | null;
  City: string | null;
  State: string | null;
  Zip: string | null;
  CensusGeoCode: string | null;
  CountyCode: string | null;
}
export interface EmploymentReportItem {
  FirstReportedDate: string | null;
  LastUpdatedDate: string | null;
  Origination: ReportItemObject;
  Name: string | null;
  AddressFirstLine: string | null;
  AddressSecondLine: string | null;
  AddressExtraLine: string | null;
  Zip: string | null;
}
export interface InformationalMessageReportItem {
  MessageNumber: string | null;
  MessageText: string | null;
}

export interface VantageScoreReportItem {
  positiveScoreFactorCodeOne: string | null;
  positiveScoreFactorCodeTwo: string | null;
  positiveScoreFactorCodeThree: string | null;
  positiveScoreFactorCodeFour: string | null;
  ModelIndicator: ReportItemObject;
  Score: string | null;
  ScoreFactorCodeOne: string | null;
  ScoreFactorCodeTwo: string | null;
  ScoreFactorCodeThree: string | null;
  ScoreFactorCodeFour: string | null;
  ScoreFactorCodeFive: string | null;
  Evaluation: ReportItemObject;
}

export interface CreditReportHeader {
  ReportDate: string | null;
  ReportTime: string | null;
}
export interface ConsumerAssistanceReferralAddressItem {
  OfficeName: string | null;
  StreetName: string | null;
  POBox: string | null;
  CityStateZip: string | null;
  Phone: string | null;
}
export interface CreditReportItem {
  Header: CreditReportHeader | null;
  ConsumerIdentity: ConsumerReportItem[] | null;
  AddressInformation: AddressInformationReportItem[] | null;
  EmploymentInformation: EmploymentReportItem[] | null;
  PublicRecord: PublicRecordItem[] | null;
  Inquiry: InquiryReportItem[] | null;
  TradeLine: TradeLineReportItem[] | null;
  RiskModel: VantageScoreReportItem[] | null;
  ConsumerAssistanceReferralAddress: ConsumerAssistanceReferralAddressItem | null;
  ProfileSummary: ProfileSummaryResponse | null;
  Statement: string | null; //This unknown yet
  InformationalMessage: InformationalMessageReportItem[] | null;
}

export const initialCreditReportValue: CreditReportItem = {
  Header: null,
  ConsumerIdentity: null,
  AddressInformation: null,
  EmploymentInformation: null,
  PublicRecord: null,
  Inquiry: null,
  TradeLine: null,
  RiskModel: null,
  ConsumerAssistanceReferralAddress: null,
  ProfileSummary: null,
  Statement: null,
  InformationalMessage: null,
};
export interface CreditProfileReport {
  success: boolean | null;
  error: {
    Code: string | null;
    Message: string | null;
    Status: number | null;
    FieldErrors: string | null;
  };
  report: CreditReportItem | null;
}

export const initialCreditProfileValue: CreditProfileReport = {
  success: null,
  error: {
    Code: null,
    Message: null,
    Status: null,
    FieldErrors: null,
  },
  report: initialCreditReportValue,
};

export interface OrderResponse {
  DOBSupplied: string | null;
  ConsumerStatement: string | null;
  Message: string | null;
  LAST: string | null;
  Reference: string | null;
  ResponseID: string | null;
  FIRST: string | null;
  MIDDLE: string | null;
  Disclaimer: string | null;
  Name: string | null;
}

export interface CandidateCriminalRecordItem {
  Restitution: string | null;
  Description: string | null;
  CommitmentLocation: string | null;
  SentenceLength: string | null;
  ArrestDate: string | null;
  WarrantDate: string | null;
  SupervisionDate: string | null;
  Court: string | null;
  ConvictionDate: string | null;
  Fines: string | null;
  OffenseDate: string | null;
  SupervisionState: string | null;
  Statute: string | null;
  ProbationSentenceLength: string | null;
  CommitmentCounty: string | null;
  OffenseCode: string | null;
  Plea: string | null;
  FBINumber: string | null;
  Status: string | null;
  ArrestingAgency: string | null;
  SupervisionCounty: string | null;
  CommitmentState: string | null;
  ConvictionLocation: string | null;
  WarrantCounty: string | null;
  ReleaseDate: string | null;
  CommitmentDate: string | null;
  CourtCosts: string | null;
  NCICcode: string | null;
  FilingDate: string | null;
  AmendedDisposition: string | null;
  CaseType: string | null;
  Disposition: string | null;
  DispositionDate: string | null;
  SuspendedSentence: string | null;
  NCICcodeDescription: string | null;
  BookingNumber: string | null;
}
export interface OffenseSubjectInfo {
  Category: string | null;
  Address: string | null;
  Sex: string | null;
  Image: string | null;
  Source: string | null;
  EyeColor: string | null;
  DOB: string | null;
  Race: string | null;
  CaseNumber: string | null;
  Height: string | null;
  DriversLicense: string | null;
  Age: string | null;
  Status: string | null;
  Comments: string | null;
  HairColor: string | null;
  Weight: string | null;
  DeathVerificationCode: string | null;
  SkinTone: string | null;
  Jurisdiction: string | null;
  ScarsMarks: string | null;
  State: string | null;
  Alias: string | null;
  FullName: string | null;
  MilitaryService: string | null;
  AgeAtDeath: string | null;
}
export interface CriminalCandidateItem {
  Offenses: {
    count: string | null;
    Offense: CandidateCriminalRecordItem[] | null;
  };
  Subject: OffenseSubjectInfo | null;
}
export interface CICcriminalItem {
  Candidates: {
    Candidate: CriminalCandidateItem | null;
    count: string | null;
  };
}
export interface CriminalReportItem {
  success: boolean | null;
  response: {
    XMLCRIM: {
      OrderResponse: OrderResponse;
      CICcriminal: CICcriminalItem | null;
    };
  };
  error?: {
    Code: string | null;
    Message: string | null;
    Status: number | null;
    FieldErrors: string | null;
  } | null;
}

export interface ReportHeadingProps {
  heading: string;
  counter?: number;
  hasCounter?: boolean;
  customHeadingClass?: string;
}
export interface EvictionCandidateActivity {
  Judgement_Rel_Date: string | null;
  Dismissal_Date: string | null;
  Notice_Type: string | null;
  Judgement_Sat_Amount: string | null;
  Judgement_Date: string | null;
  Default: string | null;
  Plaintiff_Atty_Phone: string | null;
  PlaintiffPhone: string | null;
  Court: string | null;
  Plaintiff: string | null;
  Judgement: string | null;
  Judgement_Amount: string | null;
  Filing_Date: string | null;
  Plaintiff_Atty: string | null;
  Case_No: string | null;
}
export interface EvictionCandidate {
  Address: string | null;
  Zone: string | null;
  FirstName: string | null;
  State: string | null;
  ZipCode: string | null;
  Activity: EvictionCandidateActivity;
  LastName: string | null;
  City: string | null;
  Middle: string | null;
}
export interface EvictionReportResponse {
  Candidate: EvictionCandidate;
  'Count_National-1': string | null;
  'Count_West-4': string | null;
  Count: string | null;
}
export interface EvictionReportItem {
  success: boolean | null;
  response: {
    XML2025: {
      OrderResponse: OrderResponse;
      Response: EvictionReportResponse;
    };
  };
  error?: {
    Code: string | null;
    Message: string | null;
    Status: number | null;
    FieldErrors: string | null;
  } | null;
}
export interface SubReportProps {
  heading: string;
  isHeadingColored?: boolean;
  customHeadingClass?: string;
  subReportItems: Record<string, string | number | boolean | null | ReportItemObject>;
  notToBoldItems?: string[];
  toBoldItems?: string[];
  customContainerClass?: string;
}
export interface BackgroundData {
  success: boolean | null;
  error: {
    Code: string | null;
    Message: string | null;
    Status: number | null;
    FieldErrors: string | null;
  } | null;
  report: {
    criminalReport: CriminalReportItem;
    evictionReport: EvictionReportItem;
  } | null;
}
export const initialCriminalReportValue: CriminalReportItem = {
  success: null,
  response: {
    XMLCRIM: {
      OrderResponse: {} as OrderResponse,
      CICcriminal: {} as CICcriminalItem,
    },
  },
};
export const initalEvictionReportValue: EvictionReportItem = {
  success: null,
  response: {
    XML2025: {
      OrderResponse: {} as OrderResponse,
      Response: {} as EvictionReportResponse,
    },
  },
};

export const initialBackgroundDataValue: BackgroundData = {
  success: null,
  error: null,
  report: null,
};

export interface ExperianReportsResponse {
  success: boolean | null;
  error: {
    Code: string | null;
    Message: string | null;
    Status: number | null;
    FieldErrors: string | null;
  } | null;
  reports: {
    CreditProfile: CreditProfileReport;
    BackgroundData: BackgroundData;
  };
  transactionId: string | null;
}

export interface NovaReport {
  pdfReportUrl: string;
  jsonReportUrl: string;
}

export const InitialExperianReportsValue: ExperianReportsResponse = {
  success: null,
  error: null,
  reports: {
    CreditProfile: initialCreditProfileValue,
    BackgroundData: initialBackgroundDataValue,
  },
  transactionId: null,
};
export interface ReportErrorMessageProps {
  errorType?: string | undefined;
  errorCode?: string | null;
  errorMessage?: string;
  shouldShowLoaderRows?: boolean;
  isSuperAdmin?: boolean;
  experianAuth?: ExperianAuthenticationStatus;
}
export interface RenterReportFlowProps {
  isRenterFlow?: boolean;
}
export interface ConsumerIdentityProps {
  consumerIdentity: ConsumerReportItem[] | null;
  vantageScore: VantageScoreReportItem[] | null;
}
export interface AddressReportInformationProps extends RenterReportFlowProps {
  addressInformation: AddressInformationReportItem[] | null;
}
export interface EmploymentInformationProps extends RenterReportFlowProps {
  employmentInformation: EmploymentReportItem[] | null;
}
export interface ProfileSummaryProps extends RenterReportFlowProps {
  profileSummary: ProfileSummaryResponse | null;
}
export interface PublicRecordInformationProps extends RenterReportFlowProps {
  publicRecordInformation: PublicRecordItem[] | null;
}
export interface CandidateOffenseReportProps extends RenterReportFlowProps {
  orderResponse: OrderResponse;
  criminalOffense: CandidateCriminalRecordItem;
}
export interface EvictionReportProps extends RenterReportFlowProps {
  evictionReportResponse: EvictionReportResponse;
  orderResponse: OrderResponse;
}
export interface ProfileReportProps extends RenterReportFlowProps {
  profileSummary: ProfileSummaryResponse;
}
export interface PublicRecordReportProps extends RenterReportFlowProps {
  publicRecord: PublicRecordItem;
}
export interface TradeLineReportProps extends RenterReportFlowProps {
  tradeLineReport: TradeLineReportItem;
}
export interface TradeLineInformationProps extends RenterReportFlowProps {
  tradeLineInformation: TradeLineReportItem[] | null;
}

//Financial Report Types
export interface Transaction {
  transactionDate: string;
  description: string;
  amount: number;
}

export interface Account {
  institutionName: string;
  accountName: string;
  accountType: string;
  accountNumberDisplay: string;
  financialInstitutionIcon: string;
  balance: number;
  currency: string;
}

export interface AccountDetails {
  account: Account;
  transactions: Transaction[];
}

export interface IncomeSummary {
  pastThreeMonths: number;
  pastSixMonths: number;
  averageMonthly: number;
  projectedTwelveMonthly: number;
}
export interface FinancialData {
  incomeSummary: IncomeSummary;
  accountDetails: AccountDetails[];
}
export interface ReportRowProps {
  transactionDate: string;
  description: string;
  amount: number;
  isGreyBackground?: boolean;
}
export interface IncomeReportItemProps {
  month: string;
  transactions: Transaction[];
}
export interface FinancialReportBodyProps {
  transactions: Transaction[][];
}
export interface IncomeReportProps {
  past3months: number;
  past6months: number;
  averageMonthly: number;
  projected12Months: number;
  propertyRent?: number;
  isCustomProperty?: boolean;
}
export interface SourceInformationBoxProps {
  companyName?: string;
  companySubName?: string;
  employmentType: string;
  jobPosition?: string;
  startDate?: string | Date;
  monthlyIncome: number;
  status: string | null;
  description?: string;
  explaination?: string;
}
export interface IncomeReportModalProps {
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  organizationId: number;
  renterId: number;
  propertyRent?: number;
  isCustomProperty?: boolean;
  totalOccpants: number;
  propertyAddress: string;
  status: PropertyApplicationStatus;
  applicationId: number;
}
export interface RenterInformationHeaderProps {
  renterName: string;
  status: PropertyApplicationStatus | undefined | string;
  totalOccupants: number | string;
  propertyAddress?: string;
  applicationStatus?: string;
  customStatusClassName?: string;
}
export interface FinancialReportProps {
  accountInformation: Account;
  accountTransactions: Transaction[];
  shouldShowInformationByDefault?: boolean;
}
export interface EmptyBackgroundInfoProps {
  page: string;
  fullName: string;
  dateOfBirth: string;
  applyingZip: string;
  reportDate: string;
  reportId: string;
}

export interface GuarantorViewPDFProps {
  email: string;
  relationship: string;
}
export interface HeadingPDFProps {
  isSubHeadingLeft?: boolean;
  subHeading?: string;
  shouldShowCount?: boolean;
  count?: number;
  heading?: string;
  shouldShowLine?: boolean;
}
export interface YearData {
  [key: string]: string[];
}
