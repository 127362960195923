import { FunctionComponent, ReactNode, SVGProps } from 'react';
import Modal, { ModalProps } from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { isSaving } from 'redux/selectors/propertySelector';

import { ReactComponent as CrossIcon } from 'assets/svgs/CrossIcon.svg';
import { ReactComponent as SyncIcon } from 'assets/svgs/SyncIcon.svg';

import RCButton from '../Button/Button';

import styles from './Modal.module.scss';

export type IModelProps = ModalProps & {
  title?: string;
  buttonText?: string;
  isImagesLoading?: boolean;
  children: React.ReactElement;
  size?: string;
  crossButtonClassName?: string;
  contentClassName?: string;
  customCrossIcon?: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  customTitleIcon?: ReactNode;
  backDropClassName?: string;
  customTitleClassName?: string;
  customContainerClassName?: string;
  isCrossIconVisible?: boolean;
  titleDescription?: string;
  customHeaderClassName?: string;
};
const RCModal = ({
  title,
  buttonText,
  size,
  contentClassName,
  onHide,
  isImagesLoading,
  crossButtonClassName,
  backDropClassName,
  dialogClassName,
  customTitleClassName,
  customContainerClassName,
  className,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  customCrossIcon: CustomCrossIcon,
  customTitleIcon,
  customHeaderClassName,
  titleDescription,
  applicationStatus,
  customStatusClassName,
  isCrossIconVisible = true,
  ...props
}: IModelProps): JSX.Element => {
  const isDataSaving = useSelector(isSaving);

  return (
    <Modal
      {...props}
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName={classNames(backDropClassName, styles.backdropColor)}
      contentClassName={contentClassName ?? styles.contentClassName}
      dialogClassName={classNames(dialogClassName, styles.dialogClassName)}
      className={className}
    >
      <div className={classNames(styles.container, customContainerClassName)}>
        <Modal.Header className={customHeaderClassName}>
          <div className={styles.HeaderContent}>
            <div className={styles.HeaderTitle}>
              <Modal.Title
                id="contained-modal-title-vcenter"
                className={classNames(styles.title, customTitleClassName)}
              >
                {customTitleIcon && <span className={styles.titleIcon}>{customTitleIcon}</span>}
                {title}
                {applicationStatus && <span className={classNames(customStatusClassName)}>{applicationStatus}</span>}
                {isDataSaving && (
                  <span className={styles.syncIcon}>
                    <SyncIcon className={styles.icon} /> saving
                  </span>
                )}
              </Modal.Title>
              {isCrossIconVisible && (
                <RCButton
                  className={classNames(styles.buttonWidth, crossButtonClassName)}
                  variant="outline"
                  onClick={onHide}
                  disabled={isImagesLoading}
                >
                  {buttonText}
                  {CustomCrossIcon ? (
                    <CustomCrossIcon className={styles.crossIcon} />
                  ) : (
                    <CrossIcon className={styles.crossIcon} />
                  )}
                </RCButton>
              )}
            </div>
            {!!titleDescription && <div className={styles.TitleDescription}>{titleDescription}</div>}
          </div>
        </Modal.Header>
      </div>
      {props.children}
    </Modal>
  );
};

export default RCModal;
