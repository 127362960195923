import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { addDays, differenceInDays, format } from 'date-fns';
import {
  selectAgentProfileInformation,
  selectedRenterDetails,
  selectRenterCreditProfile,
  selectRenterNovaReport,
} from 'redux/selectors/agentSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { getRenterExperianReport, getRenterNovaReport } from 'redux/slices/agentSlice';
import { getAllProperties } from 'redux/slices/propertySlice';
import { AppThunkDispatch } from 'redux/store';

import { ReactComponent as ArrowIcon } from 'assets/svgs/BlackDownArrow.svg';
import { ReactComponent as LoadingIcon } from 'assets/svgs/BlackLoadingIcon.svg';
import { ReactComponent as DocumentIcon } from 'assets/svgs/DocumentIcon.svg';
import { ReactComponent as ExperianIcon } from 'assets/svgs/ExperianLogoFull.svg';
import { ReactComponent as InformationIcon } from 'assets/svgs/InformationIcon.svg';
import { ReactComponent as NoSsnLogo } from 'assets/svgs/NoSsnLogo.svg';
import InviteApplicantModal from 'components/Agent/components/shared/InviteApplicantModal/InviteApplicantModalHandler';
import { Ladder } from 'components/Renter/IdentityVerification/RenterDetails/shared/Ladder/Ladder';
import AgentPills, { PillTypes } from 'components/shared/AgentPills/AgentPills';
import RCButton from 'components/shared/Button/Button';
import { DocumentPreview } from 'components/shared/DocumentPreview/DocumentPreview';
import { ApplicationRequestValues } from 'constants/applicationConstants';
import { dateFormatFrontend } from 'constants/calendarConstants';
import { CreditCheckProvider, ExperianAuthenticationStatus } from 'constants/renterConstants';
import { ReportErrorTypes, reportExpireyErrorCode } from 'constants/reportConstants';
import { getRemainingDaysPillType } from 'helpers/reportsHelper';
import { DOCUMENT_FORMATS, IMAGE_FORMATS } from 'shared/constants';
import { UserType } from 'shared/types/authType';
import { PropertyType } from 'shared/types/propertType';
import { VisaDocument } from 'shared/types/renterTypes';

import DocumentItem from '../shared/Documents/DocumentItem/DocumentItem';

import { CreditReport } from './components/CreditReport/CreditReport';
import { CriminalReport } from './components/CriminalReport/CriminalReport';
import { HousingCourtRecords } from './components/HousingCourtRecords/HousingCourtRecords';
import { ReportStatusLoader } from './components/ReportStatusLoader/ReportStatusLoader';

import styles from './RenterCreditAndBackground.module.scss';

const RenterCreditAndBackground = (): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const { t } = useTranslation();
  const { visaDocuments } = useSelector(selectedRenterDetails);
  const { visaTypeDescription } = useSelector(selectedRenterDetails);
  const [selectedDocument, setSelectedDocument] = useState<undefined | VisaDocument>(
    visaDocuments ? visaDocuments[0] : undefined
  );
  const { applicationId } = useParams();
  const { creditCheckProvider, experianAuth } = useSelector(selectedRenterDetails);
  const { visaType } = useSelector(selectedRenterDetails);
  const isNovaReportLoaded = useRef(false);
  const novaReport = useSelector(selectRenterNovaReport);
  const currentOrganization = useSelector(selectedOrganization);
  const [isReportLoaded, setIsReportLoaded] = useState(false);
  const [isReportExpired, setIsReportExpired] = useState(false);
  const [isReportError, setIsReportError] = useState(false);
  const applicant = useSelector(selectAgentProfileInformation);
  const isSuperAdmin = applicant.agentProfessionalProfile?.role === UserType.SUPERADMIN;
  const renterCreditProfile = useSelector(selectRenterCreditProfile);
  const [isCreditReportError, setIsCreditReportError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const getDate30DaysAhead = useCallback((date: string): string => {
    if (!date) {
      return '—';
    }

    return format(addDays(new Date(date), 30), dateFormatFrontend);
  }, []);
  const getReportStatusMessage = useCallback((): string => {
    let message = '';

    if (!isReportLoaded || (isSuperAdmin && experianAuth === ExperianAuthenticationStatus.COMPLETED)) {
      message = ReportErrorTypes.IN_PROGRESS;
    } else if (isReportExpired && isReportLoaded) {
      message = ReportErrorTypes.EXPIRED;
    } else if (isReportError && !isReportExpired && isReportLoaded) {
      message = ReportErrorTypes.ERROR;
    }

    return message;
  }, [isReportLoaded, isReportExpired, isReportError, isSuperAdmin, experianAuth]);
  const getRemainingDays = useMemo((): number | string => {
    if (!renterCreditProfile.report || !renterCreditProfile.success) {
      return '–';
    }

    const difference =
      differenceInDays(
        new Date(getDate30DaysAhead(renterCreditProfile?.report?.Header?.ReportDate ?? '')),
        new Date()
      ) + 1;

    return difference > 0 ? difference : 0;
  }, [getDate30DaysAhead, renterCreditProfile]);
  const getReportStatusPill = (): PillTypes => {
    if (isReportExpired) {
      return PillTypes.SECONDARY;
    }

    if (isReportError || isCreditReportError) {
      return PillTypes.DANGER;
    }

    return PillTypes.PRIMARY_CYAN_LIGHT;
  };
  const getReportStatusHeading = useMemo((): string => {
    if (experianAuth === ExperianAuthenticationStatus.COMPLETED && isSuperAdmin) {
      return '';
    }

    if (isReportExpired) {
      return t('agent.creditReport.expiredReport');
    }

    if (isReportError || isCreditReportError) {
      return t('application.warning');
    }

    return t('agent.creditReport.inProgressReport');
  }, [isCreditReportError, isReportError, isReportExpired, t, isSuperAdmin, experianAuth]);
  const getReportStatusTexts = useMemo((): string[] => {
    if (isReportExpired) {
      return [t('agent.creditBackground.expiredText'), ` ${t('agent.properties.learnMore')}`];
    }

    if (isReportError || isCreditReportError) {
      return [t('agent.creditBackground.warningText'), ` ${t('rental.historyInformation.contactSupport')}`];
    }

    return [t('agent.creditBackground.inProgressText'), ` ${t('agent.properties.learnMore')}`];
  }, [isCreditReportError, isReportError, isReportExpired, t]);
  const hasReportErrors = useMemo(
    (): boolean => isReportError || isReportExpired || !isReportLoaded,
    [isReportError, isReportExpired, isReportLoaded]
  );
  const areThereAnyErrors = useMemo(
    (): boolean => hasReportErrors || isCreditReportError,
    [hasReportErrors, isCreditReportError]
  );
  const RenderDocumentPreview = useCallback(() => {
    if (selectedDocument && selectedDocument?.fileType) {
      if (DOCUMENT_FORMATS.includes(selectedDocument.fileType.toUpperCase())) {
        return <DocumentPreview link={selectedDocument.fileLink as string} />;
      }

      if (IMAGE_FORMATS.includes(selectedDocument.fileType.toUpperCase())) {
        return (
          <img
            src={selectedDocument.fileLink}
            alt={`visa-document-${selectedDocument.fileName}`}
            className={styles.DocumentImage}
          />
        );
      }

      return <div className={styles.PreviewText}>No preview available for this file</div>;
    }

    return <div className={styles.PreviewText}>Select file to preview</div>;
  }, [selectedDocument]);
  const handleOpenModal = useCallback(() => {
    dispatch(
      getAllProperties({
        propertyId: undefined,
        organizationId: currentOrganization.id,
        propertyStatus: PropertyType.PUBLISHED,
      })
    )
      .unwrap()
      .then(() => setIsModalOpen(true));
  }, [currentOrganization.id, dispatch]);

  useEffect(() => {
    if (
      creditCheckProvider === CreditCheckProvider.EXPERIAN &&
      currentOrganization.id &&
      applicationId &&
      !isReportLoaded &&
      !isSuperAdmin
    ) {
      dispatch(
        getRenterExperianReport({ organizationId: currentOrganization.id, applicationId: Number(applicationId) })
      )
        .unwrap()
        .then((res) => {
          if (!res?.success) {
            setIsReportError(true);

            return;
          }
        })
        .catch((errorCode) => {
          setIsReportExpired(errorCode.message === reportExpireyErrorCode);
          setIsReportError(true);
        })
        .finally(() => {
          setIsReportLoaded(true);
        });
    }
  }, [applicationId, creditCheckProvider, currentOrganization, dispatch, isReportLoaded, isSuperAdmin]);

  useEffect(() => {
    if (creditCheckProvider === CreditCheckProvider.NOVA) {
      dispatch(getRenterNovaReport({ organizationId: currentOrganization.id, applicationId: Number(applicationId) }))
        .unwrap()
        .then(() => {
          isNovaReportLoaded.current = true;
        });
    }
  }, [applicationId, creditCheckProvider, currentOrganization.id, dispatch]);
  useEffect(() => {
    if (renterCreditProfile?.success === false) {
      setIsCreditReportError(true);
    }
  }, [renterCreditProfile]);

  return (
    <div className={styles.Container}>
      {creditCheckProvider === CreditCheckProvider.EXPERIAN && (
        <>
          <div className={styles.ReportValidity}>
            <div className={styles.RemainingDays}>
              <div className={styles.SpaceProvider} />
              <AgentPills
                pillContainerClassName={styles.PillContainer}
                pillsData={[
                  {
                    heading: `${isReportExpired ? 0 : getRemainingDays} ${t('agent.creditReport.days')}`,
                    type: getRemainingDaysPillType(isReportExpired ? 0 : getRemainingDays),
                  },
                ]}
              />
            </div>
            <div className={styles.CreditBackgroundHeader}>
              <div className={styles.ExperianLogoContainer}>
                <div className={styles.LogoWithButtons}>
                  <div className={styles.ExperianLogo}>
                    <ExperianIcon />
                    {areThereAnyErrors && (
                      <AgentPills
                        pillContainerClassName={styles.PillContainer}
                        pillsData={[
                          {
                            heading: getReportStatusHeading,
                            type: getReportStatusPill(),
                          },
                        ]}
                      />
                    )}
                  </div>
                  {(isReportExpired || !isReportLoaded) && (
                    <div className={styles.ButtonsContainer}>
                      {isReportExpired && (
                        <>
                          <RCButton className={styles.ReportButton} variant="outline">
                            {t('agent.creditBackground.savedFiles')}
                          </RCButton>
                          <RCButton onClick={handleOpenModal} className={styles.ReportButton} variant="outline">
                            {t('agent.creditBackground.requestNew')}
                          </RCButton>
                        </>
                      )}
                      {!isReportLoaded && !isSuperAdmin && (
                        <RCButton className={styles.ReportButton} variant="outline">
                          <LoadingIcon />
                          {t('agent.creditBackground.reloadButton')}
                        </RCButton>
                      )}
                    </div>
                  )}
                </div>
                {areThereAnyErrors && !isSuperAdmin && (
                  <div className={styles.ReportStatusText}>
                    {getReportStatusTexts[0]}
                    <Link to="#" className={styles.LinkText}>
                      {getReportStatusTexts[1]}
                    </Link>
                  </div>
                )}
              </div>
              <div className={styles.ValidityDates}>
                <div className={styles.DateContainer}>
                  <div className={styles.Label}>{t('agent.creditReport.returned')}</div>
                  <div
                    className={classNames(styles.Date, {
                      [styles.NoDate]: !renterCreditProfile?.report?.Header?.ReportDate,
                    })}
                  >
                    {renterCreditProfile?.report?.Header?.ReportDate ?? '—'}
                  </div>
                </div>
                <div className={styles.DateContainer}>
                  <div className={styles.Label}>{t('agent.creditReport.expires')}</div>
                  <div
                    className={classNames(styles.Date, {
                      [styles.NoDate]: !renterCreditProfile?.report?.Header?.ReportDate,
                    })}
                  >
                    {getDate30DaysAhead(renterCreditProfile?.report?.Header?.ReportDate ?? '')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.CreditReportContainer}>
            {areThereAnyErrors && (
              <>
                <div className={styles.CreditIconContainer}>
                  <ArrowIcon className={classNames(styles.ArrowIcon)} />
                  <div className={styles.Heading}>{t('agent.creditReport.creditReport')}</div>
                </div>

                <div className={styles.ErrorContainer}>
                  <ReportStatusLoader
                    errorType={getReportStatusMessage()}
                    isSuperAdmin={isSuperAdmin}
                    experianAuth={experianAuth}
                  />
                </div>

                {isCreditReportError && ( //Credit report data fetching failed
                  <div className={styles.ErrorContainer}>
                    <ReportStatusLoader
                      errorCode={renterCreditProfile.error.Code}
                      errorMessage={renterCreditProfile.error.Message ?? ''}
                      errorType={ReportErrorTypes.ERROR}
                    />
                  </div>
                )}
              </>
            )}
            {!areThereAnyErrors && <CreditReport />}
          </div>
          <div className={styles.CreditReportContainer}>
            {hasReportErrors && (
              <>
                <div className={styles.CreditIconContainer}>
                  <ArrowIcon className={styles.ArrowIcon} />
                  <div className={classNames(styles.Heading, styles.DisabledState)}>
                    {t('agent.creditReport.criminalRecords')}
                  </div>
                </div>
                <div className={styles.ErrorContainer}>
                  <ReportStatusLoader />
                </div>
              </>
            )}

            {!hasReportErrors && <CriminalReport />}
          </div>
          <div className={styles.CreditReportContainer}>
            {hasReportErrors && (
              <>
                <div className={styles.CreditIconContainer}>
                  <ArrowIcon className={classNames(styles.ArrowIcon)} />
                  <div className={styles.Heading}>{t('agent.creditReport.housingCourtRecords')}</div>
                </div>

                <div className={styles.ErrorContainer}>
                  <ReportStatusLoader />
                </div>
              </>
            )}
            {!hasReportErrors && <HousingCourtRecords />}
          </div>
        </>
      )}
      {creditCheckProvider === CreditCheckProvider.NOVA && isNovaReportLoaded && novaReport?.pdfReportUrl && (
        <>
          <div className={classNames(styles.outterNovaClass4, 'd-flex')}>
            <InformationIcon className={styles.InformationIcon} />
            <p className={styles.InformationText}>
              Intellirent is partnered with <b>Nova Credit</b> for international credit bureau reporting.
              <span className={classNames(styles.NovaPartner, styles.AgentLearnMore, 'mb-0 mx-1')}>
                {t('agent.applications.emptyState.learnMore')}
              </span>
            </p>
          </div>
          <DocumentPreview link={novaReport.pdfReportUrl} />
        </>
      )}
      {creditCheckProvider === CreditCheckProvider.DOCUMENT && (
        <div>
          <div className={styles.TopContainer}>
            <p className={classNames(styles.NoSsn, styles.outterNovaClass)}>{t('agent.applicants.noSsn')}</p>
            <div className={classNames('d-flex', styles.LadderWidth1)}>
              <Ladder
                ladderContainerClassName={styles.Ladder}
                verticalBranchClassName={styles.VerticalLine}
                horizontalBranchClassName={styles.HorizontalLine}
              />
              <div className={classNames(styles.outterNovaClass2, styles.NovaPartnerDiv)}>
                <NoSsnLogo className={styles.SsnLogo} />
                <div>
                  <div className="d-flex flex-wrap">
                    <div className="d-flex flex-wrap">
                      <p className={classNames(styles.AgentSpacing, styles.NovaPartner)}>
                        {t('agent.applicants.intellirentPartner')}
                      </p>
                      <p className={classNames(styles.AgentSpacing, styles.NovaPartner)}>
                        <b>{t('agent.applicants.novaCredit')}</b>
                      </p>
                      <p className={classNames(styles.AgentSpacing, styles.NovaPartner)}>
                        {t('agent.applicants.creditBureau')}
                      </p>
                    </div>
                    <p className={classNames(styles.NovaPartner, styles.AgentLearnMore)}>
                      {t('agent.applications.emptyState.learnMore')}
                    </p>
                  </div>
                  <p className={styles.NovaPTag}>I haven’t lived in any of these countries </p>
                </div>
              </div>
            </div>
            <div className={classNames('d-flex', styles.LadderWidth1)}>
              <Ladder
                ladderContainerClassName={styles.Ladder}
                verticalBranchClassName={styles.VerticalLine}
                horizontalBranchClassName={styles.HorizontalLine}
              />
              <div className={classNames(styles.outterNovaClass2, styles.NovaPTag)}>
                <p className={styles.Other}>{visaType}</p>
              </div>
            </div>

            {visaType === 'other' && (
              <div className={classNames('d-flex', styles.LadderWidth2)}>
                <Ladder
                  ladderContainerClassName={styles.Ladder2}
                  verticalBranchClassName={styles.VerticalLine}
                  horizontalBranchClassName={styles.HorizontalLine}
                />
                <div className={styles.outterNovaClass3}>
                  <p className={styles.DescriptionOther}>Description of Other </p>
                  <p className={styles.NovaPTag}>{visaTypeDescription}</p>
                </div>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-end">
            <div className={classNames('d-flex', 'mt-4', styles.Attachments)}>
              {visaDocuments?.map((document, index) => (
                <div
                  key={`visaDocument-${document.fileName}`}
                  onClickCapture={() => setSelectedDocument(document)}
                  className={classNames(styles.DocumentItem)}
                >
                  <DocumentItem
                    className="mr-5 text-start"
                    key={`attachment-${document.fileLink}`}
                    documentName={document.fileName}
                    icon={<DocumentIcon />}
                    index={index + 1}
                  />
                </div>
              ))}
            </div>
          </div>

          <RenderDocumentPreview />
        </div>
      )}
      {isReportExpired && isModalOpen && (
        <InviteApplicantModal
          inviteType={ApplicationRequestValues.REPORTS_ONLY}
          isModalOpen={isModalOpen}
          onHide={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default RenterCreditAndBackground;
