import { useTranslation } from 'react-i18next';

import AgentPills, { PillTypes } from 'components/shared/AgentPills/AgentPills';
import { CountCard } from 'components/shared/CountCard/CountCard';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import { AddressCapsuleProps } from 'shared/types/applicantsType';

import styles from './AddressCapsule.module.scss';

export const AddressCapsule = ({
  hasPresentAddress = false,
  hasPriorAddress = false,
  inProgressAddressVerificationCount,
  verifiedAddressVerificationCount,
  warningAddressVerificationCount,
}: AddressCapsuleProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles.CapsuleContainer}>
      {hasPresentAddress || hasPriorAddress ? (
        <Tooltip
          placement="top"
          text={t('agent.viewDetails')}
          icon={
            <>
              <div className={styles.AddressInformation}>
                {hasPresentAddress && <div className={styles.Address}>{t('addressBox.present')}</div>}

                {hasPriorAddress && (
                  <div className={styles.Address}>
                    {hasPresentAddress && '+ '} {t('agent.applicants.renterResume.sourceOfIncome.prior')}
                  </div>
                )}
              </div>
              <div className={styles.LabelContainer}>
                <div className={styles.CapsuleLabel}>{t('renter.ConfirmAddressHistory.addressHistory.')}</div>
                {warningAddressVerificationCount && warningAddressVerificationCount > 0 ? (
                  <AgentPills
                    pillContainerClassName={styles.PillContainer}
                    pillsData={[{ heading: t('application.warning'), type: PillTypes.DANGER_LIGHT }]}
                    pillIcon={
                      <CountCard count={warningAddressVerificationCount} customCountClassName={styles.AddressCount} />
                    }
                  />
                ) : undefined}
                {verifiedAddressVerificationCount && verifiedAddressVerificationCount > 0 ? (
                  <AgentPills
                    pillContainerClassName={styles.PillContainer}
                    pillsData={[
                      {
                        heading: t('agent.applicants.renterResume.sourceOfIncome.verified'),
                        type: PillTypes.SUCCESS_LIGHT,
                      },
                    ]}
                    pillIcon={
                      <CountCard count={verifiedAddressVerificationCount} customCountClassName={styles.AddressCount} />
                    }
                  />
                ) : undefined}
                {inProgressAddressVerificationCount && inProgressAddressVerificationCount > 0 ? (
                  <AgentPills
                    pillContainerClassName={styles.PillContainer}
                    pillsData={[
                      {
                        heading: t('agent.applications.applicant.status.pending'),
                        type: PillTypes.WARNING_DARK,
                      },
                    ]}
                    pillIcon={
                      <CountCard
                        count={inProgressAddressVerificationCount}
                        customCountClassName={styles.AddressCount}
                      />
                    }
                  />
                ) : undefined}
              </div>
            </>
          }
          customIconClassName={styles.CountContainer}
        />
      ) : (
        <Tooltip
          placement="top"
          text={t('agent.renterHasNotSubmitted')}
          icon={
            <>
              <div className={styles.EmptyState}>{'___'}</div>
              <div className={styles.LabelContainer}>
                <div className={styles.CapsuleLabel}>{t('renter.ConfirmAddressHistory.addressHistory.')}</div>
              </div>
            </>
          }
          customIconClassName={styles.CountContainer}
        />
      )}
    </div>
  );
};
