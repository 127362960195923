import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { t } from 'i18next';
import { isAgentLoading } from 'redux/selectors/agentSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { removePropertyApplication, updateApplicationFileStatus } from 'redux/slices/agentSlice';
import { AppThunkDispatch } from 'redux/store';

import { RenterInformationHeader } from 'components/Agent/Applications/ApplicationDetails/components/RenterIncomeReports/RenterInformationHeader/RenterInformationHeader';
import AgentModal from 'components/Agent/components/AgentModal/AgentModal';
import Button from 'components/shared/Button/Button';
import RCButton from 'components/shared/Button/Button';
import Spinner from 'components/shared/Spinner/Spinner';
import { ApplicationActions, PropertyApplicationStatus } from 'shared/types/agentTypes';
import { ApplicationType } from 'shared/types/applicantsType';

import styles from './ChangeApplicantStatusModal.module.scss';

type ChangeApplicantStatusProps = {
  applicationId: number | undefined;
  propertyId: number | undefined | string;
  selectedStatus: string;
  totalOccpants: number | string;
  propertyAddress?: string;
  status: PropertyApplicationStatus | undefined | string;
  application: ApplicationType;
  renterFirstName: string | undefined;
  renterLastName: string | undefined;
  setIsStatusModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsAdverseFormModalOpen: Dispatch<SetStateAction<boolean>>;
};

export const ChangeApplicantStatusModal = ({
  applicationId,
  propertyId,
  selectedStatus,
  totalOccpants,
  propertyAddress,
  status,
  application,
  setIsStatusModalOpen,
  renterFirstName,
  renterLastName,
  setIsAdverseFormModalOpen,
}: ChangeApplicantStatusProps): JSX.Element => {
  const dispatch = useDispatch<AppThunkDispatch>();
  const currentOrganization = useSelector(selectedOrganization);
  const isPageLoading = useSelector(isAgentLoading);
  const [switchSpinner, setSwitchSpinner] = useState(0);
  const handleStatusChange = useCallback((): void => {
    if (selectedStatus.toLocaleLowerCase() === ApplicationActions.EXPORT) {
      return;
    }

    setSwitchSpinner(1);

    const body = {
      applicationId: Number(applicationId),
      propertyId: Number(propertyId),
      status: selectedStatus.toLocaleLowerCase(),
    };

    dispatch(updateApplicationFileStatus({ organizationId: Number(currentOrganization.id), values: { ...body } }))
      .unwrap()
      .then(() => {
        dispatch(removePropertyApplication({ propertyId, applicationId }));
      })
      .finally(() => {
        setIsStatusModalOpen(false);
      });
  }, [selectedStatus, applicationId, propertyId, currentOrganization.id, dispatch, setIsStatusModalOpen]);
  const handleAdverseFormChange = (): void => {
    const body = {
      applicationId: Number(applicationId),
      propertyId: Number(propertyId),
      status: selectedStatus.toLocaleLowerCase(),
    };

    setSwitchSpinner(2);

    dispatch(updateApplicationFileStatus({ organizationId: Number(currentOrganization.id), values: { ...body } }))
      .unwrap()
      .then(() => {
        setIsAdverseFormModalOpen(true);
        setIsStatusModalOpen(false);
      })
      .catch((e) => {
        console.log('Error: ', e);
      });
  };
  const capitalizeStatus =
    application.fileApplication.status.charAt(0).toUpperCase() +
    application.fileApplication.status.slice(1).toLowerCase();
  const statusModalContent = (currentStatus: string): JSX.Element => {
    switch (currentStatus.toLocaleLowerCase()) {
      case ApplicationActions.DENIED:
        return (
          <>
            <div className={styles.MainContainer}>
              <div className={styles.ContainerHeading}>
                {t('agent.changeApplicationStatus.modal.heading')}
                <span className={styles.StatusText}>&nbsp;{selectedStatus}</span>.
              </div>
              <div className={styles.NottextContainerDenied}>
                {t('agent.changeApplicationStatus.modal.not.notified.denied')}
                <span className={styles.NotText}>&nbsp;not&nbsp;</span>
                {t('agent.changeApplicationStatus.modal.not.until.denied')}
              </div>
              <div className={styles.AdverseLetter}>{t('agent.changeApplicationStatus.modal.notified.denied')}</div>
              <div className={styles.NottextContainer}>{t('agent.changeApplicationStatus.save.denied')}</div>
            </div>
            <div className={styles.ButtonDeniedContainer1}>
              <RCButton
                onClick={() => setIsStatusModalOpen(false)}
                className={styles.DeniedCancelBtn}
                variant="outline"
                disabled={isPageLoading}
              >
                {t('application.cancel')}
              </RCButton>
              <div className={styles.ButtonDeniedContainer2}>
                <Button
                  onClick={() => {
                    handleStatusChange();
                  }}
                  disabled={isPageLoading}
                  className={styles.SaveBtn}
                >
                  {isPageLoading && switchSpinner === 1 ? <Spinner /> : t('NoSaveAndClose')}
                </Button>
                <Button onClick={handleAdverseFormChange} disabled={isPageLoading} className={styles.SaveBtn}>
                  {isPageLoading && switchSpinner === 2 ? <Spinner /> : t('YesSaveAndContinue')}
                </Button>
              </div>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className={styles.SecondContainer}>
              <div className={styles.ContainerHeading}>
                {t('agent.changeApplicationStatus.modal.heading')}
                <span className={styles.StatusText}>&nbsp;{selectedStatus}</span>.
              </div>
              <div className={styles.NottextContainer}>{t('agent.changeApplicationStatus.modal.not.notified')}</div>
              {application.fileApplication.status.toLowerCase() === ApplicationActions.ACTIVE && (
                <div className={styles.NottextContainer}>{t('agent.changeApplicationStatus.modal.active')}</div>
              )}
              <div className={styles.NottextContinue}>{t('agent.changeApplicationStatus.modal.continue')}</div>
            </div>
            <div className={styles.ButtonContainer}>
              <RCButton
                onClick={() => setIsStatusModalOpen(false)}
                className={styles.CancelBtn}
                variant="outline"
                disabled={isPageLoading}
              >
                {t('application.cancel')}
              </RCButton>
              <Button
                onClick={() => {
                  handleStatusChange();
                }}
                disabled={isPageLoading}
              >
                {isPageLoading ? <Spinner /> : t('saveAndClose')}
              </Button>
            </div>
          </>
        );
    }
  };

  return (
    <AgentModal
      show
      onHide={() => setIsStatusModalOpen(false)}
      size="sm"
      title={`Move file to`}
      applicationStatus={selectedStatus.toUpperCase()}
      customTitleClassName={styles.Title}
      customContainerClassName={styles.CustomCLass}
      customStatusClassName={styles[selectedStatus]}
      customHeaderClassName={styles.CustomHeader}
    >
      <>
        <RenterInformationHeader
          renterName={`${renterFirstName ? renterFirstName : '____'} ${renterLastName ? renterLastName : ''}`}
          totalOccupants={totalOccpants}
          propertyAddress={propertyAddress}
          status={status}
          applicationStatus={application.fileApplication.status}
          customStatusClassName={styles[capitalizeStatus]}
        />
        {statusModalContent(selectedStatus)}
      </>
    </AgentModal>
  );
};
