import { Dispatch, FunctionComponent, ReactNode, SetStateAction, SVGProps } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { Option } from 'pages/AgentPofileForm/types';

import {
  ExperianRegistrationStatus,
  VerificationStatus,
  VerifierQuestionStatusTypes,
  VerifierTenancyStatus,
} from 'constants/agentConstants';
import { ApplicationRequestValues } from 'constants/applicationConstants';
import { RenterAddressTypes } from 'constants/renterConstants';
import { PropertySelectType } from 'helpers/propertyHelper';

import { ApiErrorType } from './apiErrorType';
import { Invite } from './organizationTypes';
import { PaginationType } from './paginationTypes';
import { AddPropertyParams } from './propertyType';
import { InviteType } from './renterApplication';
import {
  AddressInformation,
  AddressInformationResponse,
  RenterProfileInformationProps,
  VerifierTypes,
} from './renterTypes';
import { RenterPresentEmployments } from './renterTypes';

export type AgentInviteType = {
  renterFirstName?: string;
  renterLastName?: string;
  renterEmail?: string;
  renterPhone?: string;
  propertyId?: number | string;
};

export type AgentProfileInformationProps = {
  housingProviderCategory: string | null | undefined;
  businessName?: string | null;
  businessPhoneType?: string | null;
  businessPhoneNumber?: string | null;
  businessSuite?: string | null;
  userId?: number;
  email: string;
  firstName: string;
  lastName: string;
  middleName: string | null;
  license?: string | null;
  phoneNumber: string;
  suite?: string | null;
  extension?: string | null;
  mailingStreetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  agentId?: number;
  isCustomAddressEnabled?: boolean;
  phoneType: string;
  socialSecurityNumber: string | null;
  isConsented: boolean;
  jscPayload?: string;
  hdimPayload?: string;
  experianRegistration: null | ExperianRegistrationStatus;
  lastOrganizationVisitedId?: number;
  businessMailingAddress: string | null;
  businessCity: string | null;
  businessState: string | null;
  businessZipCode: string | null;
  fileLink?: string | null;
  addressLineTwo?: string | null;
  agentProfessionalProfile?: AgentProfessionalProfileRequest;
};

export const profileInvitedScreenInitialValues: AgentProfessionalProfileRequest = {
  lastName: null,
  firstName: null,
  phoneNumber: null,
  phoneType: null,
  role: null,
  housingProviderCategory: null,
};

export const AgentInitialProfileInformation: AgentProfileInformationProps = {
  housingProviderCategory: '',
  businessName: null,
  businessPhoneType: null,
  businessPhoneNumber: null,
  businessSuite: null,
  businessMailingAddress: null,
  businessCity: null,
  businessState: null,
  businessZipCode: null,
  email: '',
  firstName: '',
  lastName: '',
  middleName: null,
  license: null,
  phoneNumber: '',
  suite: null,
  extension: null,
  mailingStreetAddress: '',
  city: '',
  state: '',
  zipCode: '',
  isCustomAddressEnabled: true,
  phoneType: '',
  socialSecurityNumber: '',
  isConsented: false,
  experianRegistration: null,
  fileLink: null,
  addressLineTwo: null,
  agentProfessionalProfile: profileInvitedScreenInitialValues,
};

export type AgentProfileInformation = {
  payload: AgentProfileInformationProps;
  errors: [] | ApiErrorType[];
};
export interface OrganizationProps {
  id?: number | undefined;
  name: string;
  roleName: string;
}

export type InviteRenterType = {
  propertyId?: number | null;
  renterFirstName?: string | null;
  renterLastName?: string | null;
  renterEmail?: string | null;
  renterPhone?: string | null;
};

export const inviteRenterInitialValues: InviteRenterType = {
  renterFirstName: '',
  renterLastName: '',
  renterEmail: '',
  renterPhone: '',
  propertyId: null,
};
export const propertyGeneralInviteInitialValues = {
  type: InviteType.PROPERTY_GENERAL,
  propertyId: null,
};
export interface AgentDummyDetailsProps {
  values: AddPropertyParams;
  agentName: string;
}
export interface AgentDetailsProps {
  values: RenterProfileInformationProps;
}

export type EmploymentProps = {
  employment: RenterPresentEmployments;
  indexValue?: number;
  fullName?: string;
  isVerifierModal?: boolean;
};

export type InfoContainerProps = {
  value: string;
  label: string;
  shouldShowPill: boolean;
};

export interface ErrorCardProps {
  onButtonClick: () => void;
  errorIcon: ReactNode;
  mainHeading: string;
  subHeading?: string;
  description?: string;
}

export interface ErrorStatusDetailsProps {
  mainHeading: string;
  subHeading: string;
  description: string | undefined;
  ErrorIcon: FunctionComponent<SVGProps<SVGSVGElement>>;
  route: string;
  value: string;
}
export interface ResumeVerifierQuestion {
  id: number;
  question: string;
  answer: string;
  dateRange?: { fromDate: string; toDate: string };
  extraInformation?: string;
  status?: string;
}

export interface VerifierNavbarItem {
  title: string;
  status?: VerifierTenancyStatus;
  address: AddressInformation | RenterPresentEmployments;
}
export type LegacyApplicantsProps = {
  id: number;
  property: string;
  name: string;
  unit: string;
  email: string;
  resume?: string;
  status: string;
  renderID?: number;
  organizationId: number;
  submittedAt?: string;
  updatedAt?: string;
  count: LegacyApplicantsTotalCount;
};
export type LegacyApplicantsTotalCount = {
  archivedCount: number;
  approvedCount: number;
  deniedCount: number;
  invitedCount: number;
};
export type TotalLegacyResponse = {
  result: LegacyApplicantsProps[];
  pageInfo: PaginationType;
  count: LegacyApplicantsTotalCount;
};
export interface ViewSubmittedDataProps {
  submittedTime?: string;
  fullName: string;
  emailAddress: string;
  phoneNumber: string;
  propertyRelation?: string;
  customContentClassName?: string;
  verifierLabel: string;
  hasPropertyRelation?: boolean;
  verifierType?: VerifierTypes;
}

export type VerifierDetailsProps = {
  verificationInformation: AddressVerificationResponse;
  address?: AddressInformationResponse;
  employment?: RenterPresentEmployments;
  isAdjacentStyled?: boolean;
  isVerifierModal?: boolean;
};
export type VerifierRow = {
  heading: string;
  data: string | undefined;
};
export interface VerifierDetailsModalProps {
  isModalOpen: boolean;
  onHideHandler: () => void;
  address?: AddressInformation;
  employment?: RenterPresentEmployments;
  title: string;
}
export interface VerifierNavbarProps {
  tenancyList: VerifierNavbarItem[];
  activeTab: AddressInformation | RenterPresentEmployments;
  setSelectedAddress?: Dispatch<SetStateAction<AddressInformation>>;
  setSelectedEmployment?: Dispatch<SetStateAction<RenterPresentEmployments>>;
}
export interface DateRangeViewerProps {
  fromDate: string;
  toDate: string;
  fromLabel?: string;
  toLabel?: string;
}
export interface ViewSubmittedItemProps {
  label: string;
  content: string;
  isEmail?: boolean;
}
export interface ViewResidencyAnswerProps {
  answer: string;
  dateRange?: { fromDate: string; toDate: string };
  extraInformation?: string;
}
export interface ViewResidencyQuestionProps {
  singleQuestion: ResumeVerifierQuestion;
  index: number;
}
export interface DateViewerProps {
  date: string;
  label: string;
  isRangeViewer?: boolean;
}
export type AddressContainerProps = {
  address: AddressInformationResponse | undefined;
  isPresent?: boolean;
  heading?: string;
  isAddressInline?: boolean;
  timePeriodLabel?: string;
  shouldShowHeading?: boolean;
  isVerifierAddress?: boolean;
  verificationInformation?: AddressVerificationResponse;
};
export interface ResidencyVerificationProps {
  verificationInformation?: AddressVerificationResponse;
  title: string;
}
export interface RentalHistoryQuestionnaireProps {
  question: string;
  index: number;
}

export interface CountCardProps {
  count: number;
  customCountClassName?: string;
  customContainerClassName?: string;
}

export enum PropertyApplicationStatus {
  COMPLETED = 'Completed',
  IN_PROGRESS = 'In Progress',
  NOT_STARTED = 'Not Started',
}
export enum ApplicationActions {
  EXPORT = 'export',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  DENIED = 'denied',
  APPROVED = 'approved',
}

export enum RenterResumeActions {
  PDF = 'PDF',
  CSV = 'CSV',
}

export enum UserFlowTpe {
  AGENT = 'agent',
  RENTER = 'renter',
}
export interface UserIndexPageProps {
  isInviteSuccess: boolean;
  setIsInviteSuccess: Dispatch<React.SetStateAction<boolean>>;
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}
export type InviteSuccessModalProps = {
  onHide: () => void;
  setIsInviteSuccess: Dispatch<React.SetStateAction<boolean>>;
  setIsModalOpen?: Dispatch<React.SetStateAction<boolean>>;
};

export interface ApplicationTooltipProps {
  isShowingOverlay: boolean;
  setIsShowingOverlay: Dispatch<React.SetStateAction<boolean>>;
  onRenterResumeSelect?: () => void;
  isShowRenterResume?: boolean;
}
export type IconType = {
  label?: string;
  customIcon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  shouldStackIconAndLabel?: boolean;
};
export interface ILabelProps extends IconType {
  url?: string;
  id?: string;
  className?: string;
  shouldStackIconAndLabel?: boolean;
}
export type PhoneTypesArray = {
  label: string;
  value: string;
};
export interface PhoneNoTypeSelectFieldProps {
  name: string;
  backgroundColor: string;
  placeholder?: string;
  ariaLabel?: string;
  hasFloatingLabel?: boolean;
  statesArray: PhoneTypesArray[];
  dropDownIcon?: React.ReactNode;
}

export interface ApplicationDetailProps {
  invite?: Invite;
}

export interface SubmitAgentOtpRequestProps {
  setHasOtpExpired: Dispatch<SetStateAction<boolean>>;
  setIsOtpRequestLoading: Dispatch<SetStateAction<boolean>>;
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>;
  navigate: NavigateFunction;
  code: string;
  setInitialOtpCode: Dispatch<SetStateAction<string>>;
}
export interface ResentOtpRequestProps {
  setInitialOtpCode: Dispatch<SetStateAction<string>>;
  setHasOtpExpired: Dispatch<SetStateAction<boolean>>;
}
export interface ApplicantPageEmptyStateProps {
  setSelectedInviteType?: Dispatch<SetStateAction<ApplicationRequestValues>>;
  setIsModalOpen?: Dispatch<SetStateAction<boolean>>;
  message?: string;
  image?: string;
  width?: number;
}
export interface SendRequestDropDownProps {
  handleModalOpen: () => void;
  setSelectedInviteType: Dispatch<React.SetStateAction<ApplicationRequestValues>>;
}

export type InviteApplicantModalViewProps = {
  isModalOpen: boolean;
  onHide: () => void;
  handleSubmit: (values: InviteRenterType) => void;
  handlePropertyChange: (option: Option) => void;
  inviteLink: string;
  isLoading: boolean;
  formattedData: PropertySelectType[];
  inviteRenterInitialValues: InviteRenterType;
  inviteType: ApplicationRequestValues;
  propertyAddress: string;
};
export type PropertyTypeProps = {
  publishedList: PropertySelectType[];
  onChangeHandler: (e: Option) => void;
  tooltipText?: string;
  inviteType: ApplicationRequestValues;
  propertyAddress: string;
};

export interface ErrorResponse {
  response: {
    data: {
      errors: [DefaultErrorResponse];
    };
  };
}

export interface DefaultErrorResponse {
  errorCode: number;
  errorMessage: string | string[];
  errorOnField: string;
}

export interface AddressVerifierInformation {
  addressId: number;
  city: string;
  state: string;
  zipCode: string;
  streetAddress: string;
  addressType: string;
  fromDate: string;
  toDate: string;
  moveInDate: string;
  rent: number;
}
export interface EmploymentVerifierInformation {
  title: string;
  company: string;
  monthlyGrossIncome: number;
  jobStartDate: string;
  presentEmploymentId: number;
}
export interface DocumentFile {
  id: number;
  fileName: string;
  fileLink: string;
  fileType: string;
  width: number;
  height: number;
  size: number;
  createdAt: string;
}

export interface NumberDetailsType {
  numberDetails: number;
}
export interface DateRangeType {
  toDate: string;
  fromDate: string;
}
export interface ExtraDetailsType {
  textDetails: string;
}
export interface VerifierQuestionResponse {
  id: number;
  question: string;
  answer: string;
  status: VerifierQuestionStatusTypes;
  meta?: NumberDetailsType | DateRangeType | ExtraDetailsType;
}

export interface AddressVerificationResponse {
  renterVerifierFullName: string;
  renterVerifierEmail: string;
  renterVerifierPhoneNumber: string;
  submitVerifierFullName: string;
  submitVerifierEmail: string;
  submitVerifierPhoneNumber: string;
  submitVerifierCompany: string;
  submitVerifierTitle: string;
  submitVerifierRelationshipProperty: string;
  submittedAt: string | null;
  requestedAt: string | null;
  verifierType: string;
  verifierStatus: VerificationStatus;
  renterAddressInformation?: AddressVerifierInformation;
  renterEmploymentInformation?: EmploymentVerifierInformation;
  submitVerifierDocument: DocumentFile[];
  submittedVerifierResponse: VerifierQuestionResponse[];
}

export interface VerificationInformationResponse {
  errors: ApiErrorType[];
  payload: AddressVerificationResponse;
}

export interface VerificationInformationRequestProps {
  organizationId: number;
  renterId: number;
}

export interface TransactionRequestProps {
  organizationId: number;
  renterId: number;
  applicationId: number;
}

export interface AddressVerificationInformationRequest extends VerificationInformationRequestProps {
  addressId: number;
}
export interface EmploymentVerificationInformationRequest extends VerificationInformationRequestProps {
  employmentId: number;
}

export interface VerifierAddressObject {
  id: number;
  addressType: RenterAddressTypes;
}
export interface VerifierAddressResponse {
  errors: ApiErrorType[];
  payload: VerifierAddressObject[];
}
export interface VerifierEmploymentObject {
  id: number;
  jobDescription: string;
}
export interface VerifierEmploymentResponse {
  errors: ApiErrorType[];
  payload: VerifierEmploymentObject[];
}

export interface AgentProfessionalProfileRequest {
  firstName: string | null;
  lastName: string | null;
  phoneType: string | null;
  phoneNumber: string | null;
  housingProviderCategory: string | null;
  role: string | null;
}

export interface UpdateAgentProfessionalProfileResponse {
  payload: AgentProfessionalProfileRequest;
  errors: [] | ApiErrorType[];
}
export interface AddressGeoLocation {
  latitude: number;
  longitude: number;
  timezone: string | null;
}

export type LegacyApplicantsResponse = {
  payload: TotalLegacyResponse;
  errors: [] | ApiErrorType[];
};
export type GetLegacyApplicantsParams = {
  values: LegacyApplicantsParamsType;
  organizationId: number;
};
export type LegacyApplicantsParamsType = {
  page: number;
  size: number;
  status: string;
};

export interface UpdateApplicationFileStatusType {
  applicationId: number;
  propertyId: number;
  status: string;
}

export interface UpdateApplicationFileStatusValues {
  values: UpdateApplicationFileStatusType;
  organizationId: number;
}
