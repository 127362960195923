import { Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';

import { CheckedOptionLogo } from '../../../shared/pdf/v2/svgs/CheckedOptionLogo';
import { MapPinOutlineSVG } from '../../../shared/pdf/v2/svgs/MapPinOutlineSVG';

Font.register({
  family: 'OpenSans',
  fonts: [{ src: 'https://fonts.gstatic.com/s/opensans/v18/mem8YaGs126MiZpBA-UFVZ0b.woff' }],
});

const adverseStyles = StyleSheet.create({
  documentSizing: {
    padding: '40px',
  },
  dateDesign: {
    fontSize: '11px',
    fontWeight: 600,
    textAlign: 'right',
    lineHeight: '2px',
  },
  Header1: {
    fontSize: '11px',
    fontWeight: 600,
    textAlign: 'left',
    color: '#5E6566',
    marginBottom: '2px',
  },
  Header2: {
    fontSize: '11px',
    fontWeight: 600,
    textAlign: 'left',
  },
  Header3: {
    fontSize: '10px',
    fontWeight: 600,
    textAlign: 'left',
    color: '#5E6566',
  },
  Header4: {
    fontSize: '10px',
    fontWeight: 600,
    textAlign: 'left',
    color: '#5E6566',
  },
  Header5: {
    fontSize: '14px',
    fontWeight: 600,
    textAlign: 'left',
    lineHeight: '2px',
  },
  spaceBreak: {
    marginBottom: '10px',
  },
  spaceBreak2: {
    marginBottom: '6px',
  },

  spaceBreak3: {
    marginBottom: '25px',
  },
  rentalPropertyDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-end',
  },
  mainContentFont: {
    fontSize: '11px',
    fontWeight: 400,
    textAlign: 'left',
    color: '#303333',
  },
  contentHeadlines: {
    fontSize: '11px',
    fontWeight: 600,
    textAlign: 'left',
    color: '#5E6566',
  },

  mapPin: {
    width: '13px',
    height: '12px',
  },

  checkBoxDiv: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    width: '50%',
    marginBottom: '10px',
  },

  checkBoxDiv2: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    marginBottom: '20px',
  },

  checkBoxText: {
    fontSize: '11px',
    fontWeight: 400,
    color: '#303333',
  },

  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  footerContainer: {
    padding: '25px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  footerName: {
    fontSize: '12px',
    fontWeight: 700,
    textAlign: 'left',
    fontFamily: 'OpenSans',
  },
  hrLine: {
    width: '100%',
    height: '1px',
    backgroundColor: '#D3D3D3',
    marginVertical: '10px',
  },
});

type AdversePDFProps = {
  values: {
    renterField: string[];
    checkedActions: string[];
    otherField: string;
    comments: string;
    downloadType: string;
  };
};

export const AdversePDF = (_values: AdversePDFProps): JSX.Element => (
  <Document>
    <Page size="A4">
      <View style={adverseStyles.documentSizing}>
        <View>
          <Text style={adverseStyles.dateDesign}>{'Mar 19, 2024'}</Text>
        </View>
        <View>
          <Text style={adverseStyles.Header1}>{'RENTAL PROPERTY'}</Text>
        </View>
        <View style={[adverseStyles.rentalPropertyDiv, adverseStyles.spaceBreak]}>
          <View style={adverseStyles.mapPin}>
            <MapPinOutlineSVG />
          </View>
          <Text style={adverseStyles.Header2}>{'436 Balboa St Unit 2 '}</Text>
          <Text style={adverseStyles.Header3}>{'San Francisco, CA 94118'}</Text>
        </View>
        <View style={adverseStyles.rentalPropertyDiv}>
          <Text style={adverseStyles.Header1}>{'OCCUPANT '}</Text>
          <Text style={adverseStyles.Header4}>{'Primary'}</Text>
        </View>
        <View>
          <Text style={adverseStyles.Header5}>{'Robert Uphold'}</Text>
        </View>
        <View>
          <Text style={[adverseStyles.mainContentFont, adverseStyles.spaceBreak2]}>{'Dear Robert,'}</Text>
        </View>
        <View>
          <Text style={[adverseStyles.mainContentFont, adverseStyles.spaceBreak3]}>
            {
              'Thank you for your recent rental application. After careful consideration, we regret to inform you that we are unable to approve your rental application at this time. Principle reason(s) for denial include:'
            }
          </Text>
        </View>
        <View style={adverseStyles.checkBoxContainer}>
          <View style={adverseStyles.checkBoxDiv}>
            <View>
              <CheckedOptionLogo />
            </View>
            <Text style={adverseStyles.checkBoxText}>{'Credit History'}</Text>
          </View>
          <View style={adverseStyles.checkBoxDiv}>
            <View>
              <CheckedOptionLogo />
            </View>
            <Text style={adverseStyles.checkBoxText}>{'Rental History'}</Text>
          </View>
          <View style={adverseStyles.checkBoxDiv}>
            <View>
              <CheckedOptionLogo />
            </View>
            <Text style={adverseStyles.checkBoxText}>{'Housing Court History'}</Text>
          </View>
          <View style={adverseStyles.checkBoxDiv}>
            <View>
              <CheckedOptionLogo />
            </View>
            <Text style={adverseStyles.checkBoxText}>{'Income Requirements'}</Text>
          </View>
          <View style={adverseStyles.checkBoxDiv}>
            <View>
              <CheckedOptionLogo />
            </View>
            <Text style={adverseStyles.checkBoxText}>{'Criminal History'}</Text>
          </View>
        </View>
        <View style={adverseStyles.checkBoxDiv2}>
          <View>
            <CheckedOptionLogo />
          </View>
          <Text style={adverseStyles.checkBoxText}>
            {'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus accumsan ligula eget ex interd'}
          </Text>
        </View>

        <View>
          <Text style={adverseStyles.contentHeadlines}>{'Additional Comments'}</Text>
        </View>
        <View>
          <Text style={[adverseStyles.mainContentFont, adverseStyles.spaceBreak]}>
            {'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus accumsan ligula eget ex interd'}
          </Text>
        </View>
        <View>
          <Text style={[adverseStyles.mainContentFont, adverseStyles.spaceBreak]}>
            {
              'Details about the credit bureau and credit score we used to make this decision are included at the end of this letter. This information '
            }
          </Text>
        </View>
        <View style={adverseStyles.spaceBreak}>
          <Text style={adverseStyles.mainContentFont}>{'Intellirent Solutions, INC.'}</Text>
          <Text style={adverseStyles.mainContentFont}>{'632 Commercial Street, 5th Floor'}</Text>
          <Text style={adverseStyles.mainContentFont}>{'San Francisco, CA 94111'}</Text>
          <Text style={adverseStyles.mainContentFont}>{'Toll free: (844) 755-4059'}</Text>
        </View>
        <View>
          <Text style={[adverseStyles.mainContentFont, adverseStyles.spaceBreak]}>
            {
              'For information on how to obtain a copy of your report or file a dispute, please login to your account at https://my.ir.app/login. A copy of A Summary Of Your Rights Under The Fair Credit Reporting Act is enclosed with this letter for your review.'
            }
          </Text>
        </View>
        <View>
          <Text style={[adverseStyles.mainContentFont, adverseStyles.spaceBreak]}>
            {
              'Intellirent plays no part in the decision to take any action on your rental application and is unable to provide you with specific reason(s) for not accepting your application. You have the right within 60 days of receipt of this notice to obtain a free copy of your report. You also have the right to dispute the accuracy or completeness of any information in the report furnished by logging into your account or contacting Intellirent, https://myintellirent.com/dispute-consumer-report.'
            }
          </Text>
        </View>
        <View>
          <Text style={[adverseStyles.mainContentFont, adverseStyles.spaceBreak]}>
            {
              'Intellirent obtains reports from Experian, 701 Experian Prkwy, Allen, TX 75013, www.experian.com/reportaccess, (888) 397-3742. '
            }
          </Text>
        </View>
        <View style={adverseStyles.spaceBreak3}>
          <Text style={adverseStyles.mainContentFont}>{'Sincerely,'}</Text>
          <Text style={adverseStyles.mainContentFont}>{'{Company Full name}'}</Text>
          <Text style={adverseStyles.mainContentFont}>{'{Company Street Address 1} {Company Street Address 1'}</Text>
          <Text style={adverseStyles.mainContentFont}>{'{Company City}, {Company State} {Company Zip}'}</Text>
          <Text style={adverseStyles.mainContentFont}>{'{Company Phone}'}</Text>
        </View>
      </View>
      <View style={adverseStyles.footerContainer}>
        <View style={adverseStyles.hrLine}></View>
        <View style={adverseStyles.footer}>
          <Text style={adverseStyles.footerName}>{'Lynn Blake'}</Text>
        </View>
      </View>
    </Page>
  </Document>
);
