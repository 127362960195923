import { Dispatch, SetStateAction } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { pdf } from '@react-pdf/renderer';
import saveAs from 'file-saver';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { t } from 'i18next';
import { AdversePDF } from 'pages/AgentPofileForm/AdverseForm/AdversePDF';
import { removePropertyApplication } from 'redux/slices/agentSlice';
import * as Yup from 'yup';

import GreyCross from 'assets/images/GreyCross.svg';
import { RenterInformationHeader } from 'components/Agent/Applications/ApplicationDetails/components/RenterIncomeReports/RenterInformationHeader/RenterInformationHeader';
import AgentModal from 'components/Agent/components/AgentModal/AgentModal';
// import Button from 'components/shared/Button/Button';
import RCButton from 'components/shared/Button/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import ReactSelect from 'components/shared/ReactSelect/ReactSelect';
import { adverseReasonActions, downloadOptions, renterOptions } from 'constants/agentConstants';
import { PropertyApplicationStatus } from 'shared/types/agentTypes';
import { ApplicationType } from 'shared/types/applicantsType';

// import Spinner from 'components/shared/Spinner/Spinner';
import styles from './AdverseActionLetterForm.module.scss';

type AdverseActionLetterFormProps = {
  totalOccpants: number | string;
  propertyAddress?: string;
  status: PropertyApplicationStatus | undefined | string;
  application: ApplicationType;
  renterFirstName: string | undefined;
  renterLastName: string | undefined;
  setIsAdverseFormModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsStatusModalOpen: Dispatch<SetStateAction<boolean>>;
  applicationId: number | undefined;
  propertyId: number | undefined | string;
};

export const AdverseActionLetterForm = ({
  totalOccpants,
  propertyAddress,
  status,
  application,
  renterFirstName,
  renterLastName,
  setIsAdverseFormModalOpen,
  setIsStatusModalOpen,
  propertyId,
  applicationId,
}: AdverseActionLetterFormProps): JSX.Element => {
  // const isPageLoading = useSelector(isAgentLoading);

  const dispatch = useDispatch();
  const VerifierDetailsOnVerificationInitialValues = {
    renterField: [] as string[],
    checkedActions: [] as string[],
    otherField: '',
    comments: '',
    downloadType: 'Download PDF',
  };
  const validationSchema = Yup.object({
    otherField: Yup.string().min(2, 'Name must be at least 2 characters'),
  });
  const modalNavigationHandler = (): void => {
    setIsAdverseFormModalOpen(false);
    setIsStatusModalOpen(true);
  };

  return (
    <AgentModal
      show
      onHide={() => {
        setIsAdverseFormModalOpen(false);
        dispatch(removePropertyApplication({ propertyId, applicationId }));
      }}
      size="sm"
      title={`Generate Denial/Adverse Action Letter`}
      buttonText={t('application.cancel')}
      crossButtonClassName={styles.CustomButton}
      customTitleClassName={styles.Title}
      customContainerClassName={styles.CustomCLass}
      customHeaderClassName={styles.CustomHeader}
    >
      <>
        <RenterInformationHeader
          renterName={`${renterFirstName ? renterFirstName : '____'} ${renterLastName ? renterLastName : ''}`}
          totalOccupants={totalOccpants}
          propertyAddress={propertyAddress}
          status={status}
          applicationStatus={application.fileApplication.status}
          customStatusClassName={styles.Denied}
        />
        <Formik
          initialValues={VerifierDetailsOnVerificationInitialValues}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            dispatch(removePropertyApplication({ propertyId, applicationId }));

            console.log(values);
            const blob = await pdf(<AdversePDF values={values} />).toBlob();

            saveAs(blob, 'Renter Adverse Form PDF');
          }}
        >
          {({ values, setFieldValue }) => (
            <Form className={styles.formcontainer}>
              <p className={styles.formHeading}>Address letter to</p>
              <p className={styles.formPara}>
                An action letter will be generated for each individual renter. At least one selection is required.
              </p>
              <div className={styles.DropDownContainer}>
                <div className={styles.RenterText}>Renter</div>

                <ReactSelect
                  options={renterOptions}
                  value={{ label: 'Select', value: 'Select' }}
                  className={styles.ReactSelect1}
                  onChange={(input) => {
                    if (input) {
                      if (!values.renterField.includes(input.label)) {
                        setFieldValue('renterField', [...values.renterField, input.label]);
                      }
                    }
                  }}
                />
              </div>
              <div className={styles.crossOptions}>
                {values.renterField.map((renter, index) => {
                  const [name, role] = renter.split('|');

                  return (
                    <div key={index + 'outter'} className={styles.optionButtonContainer}>
                      <button
                        className={styles.optionButtonDesign}
                        onClick={() =>
                          setFieldValue(
                            'renterField',
                            values.renterField.filter((item) => item !== renter)
                          )
                        }
                      >
                        <img src={GreyCross} alt="cross" />
                      </button>
                      <div key={index}>
                        <span className={styles.NameStyle}>{name.trim()} </span>
                        <span className={styles.RoleStyle}>| {role.trim()}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <p className={styles.formHeading}>Reasons for Action</p>
              <p className={styles.formPara}>
                Only selected options will be shown on the action letter. At least one Action is required.
              </p>
              <Row style={{ marginLeft: '0px' }}>
                {adverseReasonActions.map((action, index) => (
                  <Col md={4} sm={12} key={index} className={styles.checkBoxFields}>
                    <Field
                      as={Checkbox}
                      className={styles.checkboxLabel}
                      name="checkedActions"
                      label={action.label}
                      value={action.value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        e.target.checked
                          ? setFieldValue('checkedActions', [...values.checkedActions, e.target.value])
                          : setFieldValue(
                              'checkedActions',
                              values.checkedActions.filter((item) => item !== e.target.value)
                            )
                      }
                      checked={values.checkedActions?.includes(action.value)}
                    />
                  </Col>
                ))}
              </Row>
              <p className={styles.formHeading}>Other</p>
              <div>
                <Field id="other" name="otherField" type="text" className={styles.inputField} />
                <ErrorMessage name="otherField" component="div" />
              </div>
              <p className={styles.formHeading}>Additional Comments (Optional)</p>
              <p className={styles.formPara}>Text will be included below Reasons for Action.</p>
              <div>
                <Field id="AdComments" name="comments" type="text" className={styles.inputField} />
                <ErrorMessage name="comments" component="div" />
              </div>
              <p className={styles.formPara}>
                By clicking “Download” you are indicating that you have read and agree to the following: Intellirent is
                providing you a PDF template of an adverse action letter. However, using this adverse action letter is
                not mandated by Intellirent and this document is not the only form of adverse action letter you may use.
                We recommend you consult your own attorney about your compliance responsibilities under the FCRA and
                applicable state law, including the contents of any adverse action letter. This template should not be
                construed as legal advice, guidance or counsel. Intellirent expressly disclaims any warranties or
                responsibility, or damages associated with or arising out of information provided.
              </p>
              <div className={styles.buttonOutterDiv}>
                <RCButton className={styles.ReportButton} variant="outline" onClick={modalNavigationHandler}>
                  Go Back
                </RCButton>
                <div className={styles.buttonInnerDiv}>
                  <button className={styles.submitButton} type="submit">
                    {values.downloadType}
                  </button>
                  <ReactSelect
                    options={downloadOptions}
                    onChange={(input) => {
                      if (input) {
                        setFieldValue('downloadType', input.label);
                      }
                    }}
                    className={styles.ReactSelect2}
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </>
    </AgentModal>
  );
};
