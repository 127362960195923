/* eslint-disable @typescript-eslint/naming-convention */
import defaultProfilePic from 'assets/images/defaultProfilePicture.png';
import { ResumeVerifierQuestion } from 'shared/types/agentTypes';
import { AddressInformation } from 'shared/types/renterTypes';

export const MINUTE = 60;
export const HOUR = 3600;
export const DAY = 86400;
export const MONTH = 2592000;
export const YEAR = 31536000;
export const mobileScreenSize = 767;

export const SSN_NUMBER_VALID_LENGTH_WITH_DASHES = 11;

export const SSN_PLACEHOLDER_TEXT = '### - ## - ####';
export enum RenterResumeKeys {
  PERSONAL_PROFILE = 'Personal Profile',
  HOUSEHOLD_DETAILS = 'Household',
  ADDRESS_HISTORY = 'Address History',
  SOURCE_OF_INCOME = 'Source of Income',
  VEHICLE_INFORMATION = 'Vehicles',
  ANIMAL_INFORMATION = 'Animals',
  BACKGROUND_QUESTIONS = 'Background Questions',
}

export const RenterResumeSteps = [
  RenterResumeKeys.PERSONAL_PROFILE,
  RenterResumeKeys.HOUSEHOLD_DETAILS,
  RenterResumeKeys.SOURCE_OF_INCOME,
  RenterResumeKeys.ADDRESS_HISTORY,
  RenterResumeKeys.VEHICLE_INFORMATION,
  RenterResumeKeys.ANIMAL_INFORMATION,
  RenterResumeKeys.BACKGROUND_QUESTIONS,
];

export enum RenterResumeNavbarKeys {
  APPLICATION = 'application',
  CREDIT_AND_BACKGROUND = 'creditAndBackground',
  CRITERIA_AND_TERMS = 'criteriaAndTerms',
  RECEIPT = 'receipt',
  // PDF_PREVIEW = 'pdf_preview',
}
export enum VerifierQuestionStatusTypes {
  ACCEPTABLE = 'Acceptable',
  UNACCEPTABLE = 'Unacceptable',
}
export enum BackgroundDataTypes {
  CRIMINAL = 'Criminal',
  HOUSING = 'Housing',
}
export const VerifierQuestionStatus = [
  { label: VerifierQuestionStatusTypes.ACCEPTABLE, value: VerifierQuestionStatusTypes.ACCEPTABLE },
  { label: VerifierQuestionStatusTypes.UNACCEPTABLE, value: VerifierQuestionStatusTypes.UNACCEPTABLE },
];

export const adverseReasonActions = [
  { label: 'Credit History', value: 'Credit History' },
  { label: 'Housing Court History', value: 'Housing Court History' },
  { label: 'Criminal History', value: 'Criminal History' },
  { label: 'Rental History', value: 'Rental History' },
  { label: 'Income Requirement', value: 'Income Requirement' },
];
export const renterOptions = [
  { value: 'option1', label: 'Robert Uphold | Primary Occupant' },
  { value: 'option2', label: 'Philip Moore Brown | Co-Occupant 1' },
  { value: 'option3', label: 'Jane Macdonald | Co-Occupant 2' },
];
export const downloadOptions = [
  { value: 'pdf', label: 'Download PDF' },
  { value: 'word', label: 'Download Word' },
];

export const ResumeVerifierQuestions: ResumeVerifierQuestion[] = [
  {
    id: 1,
    question: 'Is the stated rental address and monthly rent amount correct?',
    answer: 'Yes',
  },
  {
    id: 2,
    question: 'Did the applicant live at the property during the stated period?',
    answer: 'No',
    dateRange: { fromDate: '09/01/2019', toDate: '' },
    status: VerifierQuestionStatusTypes.UNACCEPTABLE,
  },
  {
    id: 3,
    question: 'Were any checks from the applicant returned due to non-sufficient funds (NSF)?',
    answer: 'No',
  },
  {
    id: 4,
    question: 'Was any unlawful detainer ever filed against this applicant?',
    answer: 'No',
  },
  {
    id: 5,
    question:
      'Are there any outstanding amounts owed by this applicant that accrued prior to March 1, 2020, or after June 30, 2021, due to delinquent rent, utilities, late fees or damage to unit?',
    answer: 'No',
  },
  {
    id: 6,
    question: 'Did the applicant pay rent on time during the last 12 months?',
    answer: 'No',
  },
  {
    id: 7,
    question: 'Was a Three Day Notice ever served to this applicant?',
    answer: 'No',
  },
  {
    id: 8,
    question: 'Did the applicant provide notice for ending tenancy according to the terms of the rental agreement?',
    answer: 'No',
  },
];
export enum VerifierTenancyStatus {
  WARNING = 'warning',
}

export const DummyAddressesList: AddressInformation[] = [
  {
    id: 89,
    city: 'San Francisco',
    state: 'CA',
    reasonForMoving: '',
    zipCode: '94111',
    contactRent: 2323,
    phoneNumber: '2323232323',
    fullName: 'Supereme',
    streetAddress: '1234 Main St.Apt 567',
    emailAddress: 'asdas@gmail.com',
    addressType: 'PRESENT',
    fromDate: '',
    toDate: '',
    moveInDate: '2023-11-05',
    apartmentNumber: '',
    createdAt: '2023-11-06T11:37:00.620048Z',
    updatedAt: '2023-11-06T11:37:26.064060Z',
  },
  {
    id: 102,
    city: 'San Jose',
    state: 'CA',
    reasonForMoving: '',
    zipCode: '95121',
    contactRent: 2323,
    phoneNumber: '2323232323',
    fullName: 'hello',
    streetAddress: 'Chuck E. Cheese',
    emailAddress: 'sd@gmail.com',
    addressType: 'PRIOR',
    fromDate: '2020-11-07',
    toDate: '2021-11-07',
    moveInDate: '',
    apartmentNumber: 'Fontaine Road',
    createdAt: '2023-11-08T15:45:30.136796Z',
    updatedAt: '',
  },
  {
    id: 103,
    city: 'Chandler',
    state: 'AZ',
    reasonForMoving: '',
    zipCode: '85286',
    contactRent: 2323,
    phoneNumber: '2323232323',
    fullName: 'hello',
    streetAddress: 'Jollibee',
    emailAddress: 'sd@gmail.com',
    addressType: 'PRIOR',
    fromDate: '2011-11-07',
    toDate: '2012-11-07',
    moveInDate: '',
    apartmentNumber: 'East Germann Road',
    createdAt: '2023-11-08T15:46:00.647750Z',
    updatedAt: '',
  },
  {
    id: 104,
    city: 'Maineville',
    state: 'OH',
    reasonForMoving: '',
    zipCode: '45039',
    contactRent: undefined,
    phoneNumber: '',
    fullName: '',
    streetAddress: 'Testerman Park',
    emailAddress: '',
    addressType: 'PRIOR',
    fromDate: '2021-11-07',
    toDate: '2023-11-07',
    moveInDate: '',
    apartmentNumber: ', ',
    createdAt: '2023-11-08T15:46:12.706587Z',
    updatedAt: '',
  },
  {
    id: 105,
    city: 'Chicago',
    state: 'IL',
    reasonForMoving: '',
    zipCode: '60637',
    contactRent: undefined,
    phoneNumber: '',
    fullName: '',
    streetAddress: 'Museum of Science and Industry',
    emailAddress: '',
    addressType: 'PRIOR',
    fromDate: '2020-11-08',
    toDate: '2023-11-08',
    moveInDate: '',
    apartmentNumber: ', ',
    createdAt: '2023-11-09T01:30:46.706132Z',
    updatedAt: '',
  },
  {
    id: 106,
    city: 'Louisville',
    state: 'KY',
    reasonForMoving: '',
    zipCode: '40202',
    contactRent: 2323,
    phoneNumber: '2323232323',
    fullName: 'Supereme',
    streetAddress: 'KFC Yum! Center',
    emailAddress: 'asdas@gmail.com',
    addressType: 'PRIOR',
    fromDate: '2020-11-08',
    toDate: '2021-11-08',
    moveInDate: '',
    apartmentNumber: 'Arena Plaza',
    createdAt: '2023-11-09T01:31:15.473530Z',
    updatedAt: '',
  },
];
export const questionnaireAnswerOptions = [
  { label: 'YES', value: 'YES' },
  { label: 'NO', value: 'NO' },
  { label: 'NA', value: 'NA' },
];

export enum QuestionnaireAnswerOptions {
  YES = 'YES',
  NO = 'NO',
  NOT_AVAILABLE = 'NA',
}

export const RentalAnswerCheckbox = [
  { label: 'rental.questionnaireYes', value: QuestionnaireAnswerOptions.YES },
  { label: 'rental.questionnaireNo', value: QuestionnaireAnswerOptions.NO },
  { label: 'rental.questionnaireNa', value: QuestionnaireAnswerOptions.NOT_AVAILABLE },
];
export const dummyQuestionnaireAnswer = [
  { question: 'Is the stated rental address and monthly rent amount correct? ' },
  { question: 'Did the renter live at the property during the stated residency period?' },
  { question: 'Were any payments from the renter returned due to non-sufficient funds (NSF)?' },
  { question: 'Was an unlawful detainer ever filed against this renter?' },
  {
    question:
      'Are there any outstanding amounts owed by this renter due to delinquent rent, utilities, late fees or damage to unit? Covid related rules and regulations may apply.',
  },
  {
    question: 'Did the renter make monthly rent payments on time during the last 12 months?',
  },
  {
    question: 'Was a Three Day Notice ever served to this renter?',
  },
  {
    question: 'Did the renter provide notice for ending tenancy according to the terms of the rental agreement?',
  },
];

export const dummyAgentImages = [
  { src: defaultProfilePic, alt: 'woman 1' },
  { src: defaultProfilePic, alt: 'woman 2' },
];

export const relationshipOptions = [
  { label: 'Owner', value: ' – I am the legal owner & operator of the rental property' },
  { label: 'Manager', value: ' – I am the authorized representative of the rental management company' },
  { label: 'Listing Agent', value: ' – I am the authorized representative of the rental and owner' },
  { label: 'Other', value: ' (more detail required)', isItalic: true },
];

export const employerVerifierOptions = [
  { label: 'The Request needs to be completed by a different contact or department', value: 'Not a Manager' },
  { label: 'There is no record of employment for the stated Employee.', value: ' No Record Exist' },
  { label: ' (more detail required)', value: 'Other', isItalic: true },
];

export const verifierOptions = [
  { label: 'The request needs to be completed by a different contact or department.', value: 'Owner' },
  { label: 'The rental property is no longer under management or ownership.', value: 'Manager' },
  { label: 'The requesting Renter is not on the lease agreement.', value: 'Listing' },
  { label: '(more detail required)', value: 'Other', isItalic: true },
];

export const paymentFailureAttemps = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12+', value: '12+' },
];

export enum PhoneNumberTypes {
  OFFICE = 'Office',
  MOBILE = 'Mobile',
  HOME = 'Home',
}
export const PhoneNoTypes = [
  { label: PhoneNumberTypes.OFFICE, value: PhoneNumberTypes.OFFICE },
  { label: PhoneNumberTypes.MOBILE, value: PhoneNumberTypes.MOBILE },
  { label: PhoneNumberTypes.HOME, value: PhoneNumberTypes.HOME },
];

export const emailSentSuccessfullStatus = 'Email sent successfully';
export const smsSentSuccessfullStatus = 'SMS sent successfully';

export enum ExperianRegistrationStatus {
  SUCCEEDED = 'SUCCEEDED',
}

export const creditReportCriteria: { [key: string]: string } = {
  P04: 'creditReportCriteriaP4',
  P05: 'creditReportCriteriaP5',
  P06: 'creditReportCriteriaP6',
  P07: 'creditReportCriteriaP7',
  P08: 'creditReportCriteriaP8',
  P09: 'creditReportCriteriaP9',
  P10: 'creditReportCriteriaP10',
  P11: 'creditReportCriteriaP11',
  P12: 'creditReportCriteriaP12',
  P13: 'creditReportCriteriaP13',
  P14: 'creditReportCriteriaP14',
  P15: 'creditReportCriteriaP15',
  P16: 'creditReportCriteriaP16',
  P17: 'creditReportCriteriaP17',
  P18: 'creditReportCriteriaP18',
  P21: 'creditReportCriteriaP21',
  P22: 'creditReportCriteriaP22',
  P23: 'creditReportCriteriaP23',
  P29: 'creditReportCriteriaP29',
  P30: 'creditReportCriteriaP30',
  P31: 'creditReportCriteriaP31',
  P32: 'creditReportCriteriaP32',
  P33: 'creditReportCriteriaP33',
  P34: 'creditReportCriteriaP34',
  P35: 'creditReportCriteriaP35',
  P36: 'creditReportCriteriaP36',
  P39: 'creditReportCriteriaP39',
  P40: 'creditReportCriteriaP40',
  P42: 'creditReportCriteriaP42',
  P43: 'creditReportCriteriaP43',
  P44: 'creditReportCriteriaP44',
  P45: 'creditReportCriteriaP45',
  P47: 'creditReportCriteriaP47',
  P48: 'creditReportCriteriaP48',
  P49: 'creditReportCriteriaP49',
  P50: 'creditReportCriteriaP50',
  P52: 'creditReportCriteriaP52',
  P53: 'creditReportCriteriaP53',
  P54: 'creditReportCriteriaP54',
  P55: 'creditReportCriteriaP55',
  P57: 'creditReportCriteriaP57',
  P58: 'creditReportCriteriaP58',
  P61: 'creditReportCriteriaP61',
  P62: 'creditReportCriteriaP62',
  P63: 'creditReportCriteriaP63',
  P64: 'creditReportCriteriaP64',
  P65: 'creditReportCriteriaP65',
  P66: 'creditReportCriteriaP66',
  P68: 'creditReportCriteriaP68',
  P69: 'creditReportCriteriaP69',
  P70: 'creditReportCriteriaP70',
  P71: 'creditReportCriteriaP71',
  P72: 'creditReportCriteriaP72',
  P73: 'creditReportCriteriaP73',
  P74: 'creditReportCriteriaP74',
  P75: 'creditReportCriteriaP75',
  P76: 'creditReportCriteriaP76',
  P77: 'creditReportCriteriaP77',
  P78: 'creditReportCriteriaP78',
  P79: 'creditReportCriteriaP79',
  P81: 'creditReportCriteriaP81',
  P83: 'creditReportCriteriaP83',
  P84: 'creditReportCriteriaP84',
  P85: 'creditReportCriteriaP85',
  P86: 'creditReportCriteriaP86',
  P87: 'creditReportCriteriaP87',
  P88: 'creditReportCriteriaP88',
  P90: 'creditReportCriteriaP90',
  P92: 'creditReportCriteriaP92',
  P93: 'creditReportCriteriaP93',
  P94: 'creditReportCriteriaP94',
  P95: 'creditReportCriteriaP95',
  P96: 'creditReportCriteriaP96',
  P98: 'creditReportCriteriaP98',
  '04': 'creditReportCriteria4',
  '05': 'creditReportCriteria5',
  '06': 'creditReportCriteria6',
  '07': 'creditReportCriteria7',
  '08': 'creditReportCriteria8',
  '09': 'creditReportCriteria9',
  '10': 'creditReportCriteria10',
  '11': 'creditReportCriteria11',
  '12': 'creditReportCriteria12',
  '13': 'creditReportCriteria13',
  '14': 'creditReportCriteria14',
  '15': 'creditReportCriteria15',
  '16': 'creditReportCriteria16',
  '17': 'creditReportCriteria17',
  '18': 'creditReportCriteria18',
  '21': 'creditReportCriteria21',
  '22': 'creditReportCriteria22',
  '23': 'creditReportCriteria23',
  '29': 'creditReportCriteria29',
  '30': 'creditReportCriteria30',
  '31': 'creditReportCriteria31',
  '32': 'creditReportCriteria32',
  '33': 'creditReportCriteria33',
  '34': 'creditReportCriteria34',
  '35': 'creditReportCriteria35',
  '36': 'creditReportCriteria36',
  '39': 'creditReportCriteria39',
  '40': 'creditReportCriteria40',
  '42': 'creditReportCriteria42',
  '43': 'creditReportCriteria43',
  '44': 'creditReportCriteria44',
  '45': 'creditReportCriteria45',
  '47': 'creditReportCriteria47',
  '48': 'creditReportCriteria48',
  '49': 'creditReportCriteria49',
  '53': 'creditReportCriteria53',
  '54': 'creditReportCriteria54',
  '55': 'creditReportCriteria55',
  '57': 'creditReportCriteria57',
  '58': 'creditReportCriteria58',
  '61': 'creditReportCriteria61',
  '62': 'creditReportCriteria62',
  '63': 'creditReportCriteria63',
  '64': 'creditReportCriteria64',
  '65': 'creditReportCriteria65',
  '66': 'creditReportCriteria66',
  '68': 'creditReportCriteria68',
  '69': 'creditReportCriteria69',
  '71': 'creditReportCriteria71',
  '72': 'creditReportCriteria72',
  '73': 'creditReportCriteria73',
  '74': 'creditReportCriteria74',
  '75': 'creditReportCriteria75',
  '76': 'creditReportCriteria76',
  '77': 'creditReportCriteria77',
  '78': 'creditReportCriteria78',
  '79': 'creditReportCriteria79',
  '81': 'creditReportCriteria81',
  '83': 'creditReportCriteria83',
  '84': 'creditReportCriteria84',
  '85': 'creditReportCriteria85',
  '86': 'creditReportCriteria86',
  '87': 'creditReportCriteria87',
  '88': 'creditReportCriteria88',
  '90': 'creditReportCriteria90',
  '93': 'creditReportCriteria93',
  '94': 'creditReportCriteria94',
  '95': 'creditReportCriteria95',
  '96': 'creditReportCriteria96',
  '97': 'creditReportCriteria97',
  '98': 'creditReportCriteria98',
};

export enum AgentHousingProviderRoles {
  REAL_ESTATE_AGENT = 'Real Estate Agent/Broker',
  PROPERTY_MANAGER = 'Property Manager',
  INDEPENDENT_OWNER = 'Independent Owner',
  OTHER = 'Other Real Estate Professional',
}

export const Initial_IDV_Values = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

export enum VerificationStatus {
  WARNING = 'WARNING',
  VERIFIED = 'VERIFIED',
  IN_PROGRESS = 'IN PROGRESS',
}
export enum UsersControlOptions {
  TARGET = 'target',
  SORTBY = 'sortBy',
  SORTTYPE = 'sortType',
  FILTER = 'filter',
}

export const INITIAL_USER_FILTER = { value: 'All', label: 'All' };

export enum AgentProfileFormSteps {
  INVITED = 'invited',
  UN_INVITED = 'uninvited',
}
export enum LegacySortTypes {
  ASCENDING = 'Ascending',
  DESCENDING = 'Descending',
}

export enum LegacySortBy {
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  PROPERTY = 'PROPERTY',
  UNIT = 'UNIT',
  NAME = 'NAME',
  EMAIL = 'EMAIL',
}
export const SEARCH_DEBOUNCE_TIME = 300;

export const SORT_INITIAL_VALUE = {
  sortBy: LegacySortBy.NAME,
  sortType: LegacySortTypes.ASCENDING,
};
