import { Location } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { t } from 'i18next';
import { isEmpty } from 'lodash-es';
import { getAgentProfileInformation, logoutSuccess, setSecurityQuestions } from 'redux/slices/authSlice';

import { AgentHousingProviderRoles, DAY, HOUR, MINUTE, MONTH, YEAR } from 'constants/agentConstants';
import { ApplicationRequestValues } from 'constants/applicationConstants';
import { timePeriodRegex } from 'constants/regexConstant';
import { RenterAddressTypes } from 'constants/renterConstants';
import { resendAgentOtp, submitAgentOtp } from 'services/agentService';
import { EXPERIAN_EXPIREY_ERROR_CODE, EXPERIAN_USER_BLOCKED_ERROR_CODE } from 'shared/constants';
import { Notification } from 'shared/Notification/Notification';
import { routes } from 'shared/routes';
import {
  AgentProfileInformationProps,
  PropertyApplicationStatus,
  ResentOtpRequestProps,
  ResumeVerifierQuestion,
  SubmitAgentOtpRequestProps,
  VerifierNavbarItem,
  VerifierQuestionResponse,
} from 'shared/types/agentTypes';
import { ApplicationType } from 'shared/types/applicantsType';
import { SecurityQuestionsProps, SecurityQuestionsPropsRenter } from 'shared/types/authType';
import { AddressInformation, RenterApplicationProps, RenterPresentEmployments } from 'shared/types/renterTypes';

import { flattenApplicationList } from './applicantsHelper';
import { pluralize } from './user';

export const closePopOverOnClick = (): void => document.body.click();

/**
 * Scrolls the page to the view of an element with the specified ID.
 *
 * @param {string} sectionId - The ID of the target element to scroll to.
 * @returns {void}
 */
export const scrollToViewOfGivenId = (sectionId: string | null): void => {
  // Check if a sectionId is provided
  if (sectionId) {
    // Attempt to find the element with the provided sectionId in the document
    const sectionElement = document.getElementById(sectionId);

    // Check if the element with the provided sectionId exists
    if (sectionElement) {
      // Scroll smoothly to the element's view
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  }
};
export const urlMatcher = (url: string, location: Location): boolean => location.pathname.toLowerCase().includes(url);

//Function to convert last active time to a string that tells last active status
export const lastActiveFormatter = (timeStamp: string): string => {
  const currentTime = new Date().getTime();
  const parsedTimestamp = new Date(timeStamp).getTime();
  const timeDifference = currentTime - parsedTimestamp;
  // Convert milliseconds to seconds
  const seconds = Math.floor(timeDifference / 1000);

  // Determine the last active status

  //Checking if time is under 1 minute
  if (seconds < MINUTE) {
    return t('agent.userInfoBox.1Minute');
    //checking if time is under 1 hour
  } else if (seconds < HOUR) {
    const minutes = Math.floor(seconds / MINUTE);

    return `${pluralize(minutes, t('agent.userInfoBox.minute'))} ${t('agent.userInfoBox.ago')}`;
    //checking if time is under 1 day
  } else if (seconds < DAY) {
    const hours = Math.floor(seconds / HOUR);

    return `${pluralize(hours, t('agent.userInfoBox.hour'))} ${t('agent.userInfoBox.ago')}`;
    //checking if time is under a month
  } else if (seconds < MONTH) {
    const days = Math.floor(seconds / DAY);

    return `${pluralize(days, t('agent.userInfoBox.day'))} ${t('agent.userInfoBox.ago')}`;
    //checking if time is under a Year
  } else if (seconds < YEAR) {
    const months = Math.floor(seconds / MONTH);

    return `${pluralize(months, t('agent.userInfoBox.month'))} ${t('agent.userInfoBox.ago')}`;
  } else {
    //checking time is in how many years
    const years = Math.floor(seconds / YEAR);

    return `${pluralize(years, t('agent.userInfoBox.year'))} ${t('agent.userInfoBox.ago')}`;
  }
};

export const formatDateTime = (inputDatetime: string | undefined | null): string => {
  if (!inputDatetime) {
    return '';
  }

  const date = new Date(inputDatetime);
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
  const day = new Intl.DateTimeFormat('en-US', { day: 'numeric' }).format(date);
  const year = new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(date);
  const hour = new Intl.DateTimeFormat('en-US', { hour: '2-digit' }).format(date);
  const timePeriod = hour.match(timePeriodRegex);
  const minute = new Intl.DateTimeFormat('en-US', { minute: '2-digit' }).format(date);
  const formattedDateTime = `${padZero(month)}${month} ${padZero(day)}${day}, ${year} ${t('application.dateTime.at')} ${
    timePeriod && timePeriod[1]
  }:${padZero(minute)}${minute} ${timePeriod && timePeriod[2]}`;

  return formattedDateTime;
};

export const formatDate = (inputDatetime: string | undefined | null): string => {
  if (!inputDatetime) {
    return '';
  }

  const date = new Date(inputDatetime);
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
  const day = new Intl.DateTimeFormat('en-US', { day: 'numeric' }).format(date);
  const year = new Intl.DateTimeFormat('en-US', { year: 'numeric' }).format(date);
  const formattedDateTime = `${padZero(month)}${month} ${padZero(day)}${day}, ${year}`;

  return formattedDateTime;
};

export const padZero = (value: string): string => (Number(value) < 10 ? '0' : '');

/**This function is used to get the list of tenancy addresses */
export const getTenancyList = (addresses: AddressInformation[]): VerifierNavbarItem[] => {
  //Checks which address has contactFullName and add them to the list
  const tenancyList: (VerifierNavbarItem | undefined)[] = addresses.map((address, index) => {
    if (address?.isRented) {
      if (address.addressType === RenterAddressTypes.PRESENT) {
        return { title: 'Present Address', address: address };
      } else {
        return { title: `Prior Address ${index}`, address: address };
      }
    }

    return undefined;
  });

  return tenancyList.filter((item) => item !== undefined) as VerifierNavbarItem[];
};
export const getEmploymentList = (employments: RenterPresentEmployments[]): VerifierNavbarItem[] => {
  const employmentList: (VerifierNavbarItem | undefined)[] = employments.map((employment, index) => {
    if (!isEmpty(employment.presentEmploymentVerifiers)) {
      return { title: `Source of Income ${index > 0 ? index + 1 : ''}`, address: employment };
    }

    return undefined;
  });

  return employmentList.filter((item) => item !== undefined) as VerifierNavbarItem[];
};

export const findApplication = (
  applications: RenterApplicationProps[],
  applicationId: number | string
): RenterApplicationProps | undefined => applications?.find((application) => application.id === Number(applicationId));

export const checkProfilePage = ({ pathname }: Location): boolean =>
  pathname.includes(routes.login) || pathname.includes(routes.signup);

export const checkFormPage = ({ pathname }: Location): boolean =>
  pathname.includes(routes.agentProfileForm) || pathname.includes(routes.agentSecurityQuestionnaire);

export const isApplicantResume = ({ pathname }: Location): boolean => pathname.includes('renter-resume');

export const submitAgentOtpRequest = ({
  setHasOtpExpired,
  setIsOtpRequestLoading,
  dispatch,
  navigate,
  code,
  setInitialOtpCode,
}: SubmitAgentOtpRequestProps): void => {
  setHasOtpExpired(false);
  setIsOtpRequestLoading(true);
  submitAgentOtp(code)
    .then((res: AxiosResponse) => {
      if (res?.data?.payload?.kiqEnabled) {
        const questionSet = res.data.payload.kba.questionSet;

        dispatch(
          //checking if the response is with small variable naming or capital
          //if small naming convention than will format it to capital naming convention
          setSecurityQuestions(questionSet?.[0]?.questionType ? convertSecurityQuestions(questionSet) : questionSet)
        );
        navigate(routes.agentSecurityQuestionnaire);
      } else {
        dispatch(getAgentProfileInformation())
          .unwrap()
          .then(() => {
            navigate(routes.applicants);
          });
      }
    })
    .catch((error) => {
      const errorObject = error?.response?.data?.payload?.payload?.payload?.error;

      if (errorObject) {
        if (errorObject.Code === EXPERIAN_EXPIREY_ERROR_CODE) {
          setHasOtpExpired(true);
        } else {
          Notification({ message: errorObject.Message });
        }

        return;
      }

      Notification({ message: error?.response?.data?.errors?.[0]?.errorMessage });

      if (error?.response?.data?.errors?.[0]?.errorCode === EXPERIAN_USER_BLOCKED_ERROR_CODE) {
        Notification({ message: error?.response?.data?.errors?.[0]?.errorMessage });
        dispatch(logoutSuccess);
      }
    })
    .finally(() => {
      setIsOtpRequestLoading(false);
      setInitialOtpCode('');
    });
};

export const agentResendOtpRequest = ({ setInitialOtpCode, setHasOtpExpired }: ResentOtpRequestProps): void => {
  resendAgentOtp().then((res: AxiosResponse) => {
    setInitialOtpCode(
      res?.data?.payload?.crossCoreFullResponse?.clientResponsePayload?.decisionElements?.[0]?.decisions?.[0]?.value ||
        ''
    );
    setHasOtpExpired(false);
  });
};

export const retrieveAgentInviteModalTitleDescription = (
  inviteType: ApplicationRequestValues
): { title: string; description: string } => {
  if (inviteType === ApplicationRequestValues.APPLICATION_AND_REPORTS) {
    return {
      title: 'agent.inviteApplicant.applicationReports',
      description: 'agent.inviteApplicant.RequestFullRentalApplication',
    };
  } else if (inviteType === ApplicationRequestValues.REPORTS_ONLY) {
    return {
      title: 'agent.inviteApplicant.reportsOnly',
      description: 'agent.inviteApplicant.RequestCreditBackground',
    };
  }

  return {
    title: 'agent.inviteApplicant.identityVerification',
    description: 'agent.inviteApplicant.RequestIdvIndependent',
  };
};

export const retrieveAgentInviteModalPreset = (inviteType: ApplicationRequestValues): string => {
  if (inviteType === ApplicationRequestValues.APPLICATION_AND_REPORTS) {
    return 'agent.inviteApplicant.applicationReportPreset';
  } else if (inviteType === ApplicationRequestValues.REPORTS_ONLY) {
    return 'agent.inviteApplicant.reportOnlyPreset';
  }

  return 'agent.inviteApplicant.idvOnlyPreset';
};

export const isPMCUser = (category: string): boolean =>
  category === AgentHousingProviderRoles.PROPERTY_MANAGER || category === AgentHousingProviderRoles.REAL_ESTATE_AGENT;

// Function to convert an array of SecurityQuestionsPropsRenter to SecurityQuestionsProps
export const convertSecurityQuestions = (questions: SecurityQuestionsPropsRenter[]): SecurityQuestionsProps[] =>
  questions.map((question) => ({
    QuestionType: question.questionType,
    QuestionText: question.questionText,
    QuestionSelect: {
      QuestionChoice: question.questionSelect.questionChoice,
    },
  }));

export const convertVerificationResponseToQuestions = (
  questions: VerifierQuestionResponse[]
): ResumeVerifierQuestion[] =>
  questions.map((question) => {
    const { id, question: questionText, answer, status } = question;
    const resumeQuestion: ResumeVerifierQuestion = {
      id,
      question: questionText,
      answer,
      status,
    };

    if ('meta' in question && question?.meta) {
      if ('fromDate' in question?.meta && 'toDate' in question?.meta) {
        resumeQuestion.dateRange = {
          fromDate: question?.meta.fromDate,
          toDate: question?.meta.toDate,
        };
      } else if ('numberDetails' in question?.meta) {
        resumeQuestion.extraInformation = String(question?.meta.numberDetails);
      } else if ('textDetails' in question?.meta) {
        resumeQuestion.extraInformation = question?.meta.textDetails;
      }
    }

    return resumeQuestion;
  });

//Helper function to set firstName and lastName for invited Agent from the professional Details to personal Details
export const updateAgentProfileHelper = (agentProfile: AgentProfileInformationProps): AgentProfileInformationProps => {
  const professionalProfileDetails = agentProfile?.agentProfessionalProfile;

  return {
    ...agentProfile,
    firstName: professionalProfileDetails?.firstName ?? agentProfile.firstName,
    lastName: professionalProfileDetails?.lastName ?? agentProfile.lastName,
    housingProviderCategory: professionalProfileDetails?.housingProviderCategory,
  };
};

export const getApplicationStatus = (confirmedFlag: boolean): PropertyApplicationStatus =>
  confirmedFlag ? PropertyApplicationStatus.COMPLETED : PropertyApplicationStatus.IN_PROGRESS;

//This calculates the current (Status) total number of applications currently in a property
export const getTotalApplications = (applications: ApplicationType[]): number => {
  let count = 0;

  applications.map((app) => {
    count = count + flattenApplicationList([app]).length;
  });

  return count;
};

export const getAddressMissingMessage = (stateName?: string, zipCode?: string, cityName?: string): string => {
  const missingParts: string[] = [];

  if (!stateName) {
    missingParts.push('state');
  }

  if (!zipCode) {
    missingParts.push('zip code');
  }

  if (!cityName) {
    missingParts.push('county');
  }

  if (missingParts.length) {
    return `Address is missing ${missingParts.join(', ')}`;
  } else {
    return '';
  }
};
