import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { selectAgentProfileInformation } from 'redux/selectors/agentSelector';
import { isLoading, serverErrorAuth, userType } from 'redux/selectors/authSelector';
import { selectInviteCode } from 'redux/selectors/authSelector';
import { selectedOrganization } from 'redux/selectors/organizationSelector';
import { getAgentProfileInformation, setServerError } from 'redux/slices/authSlice';
import { getAgentOrganizations } from 'redux/slices/organizationSlice';
import { AppThunkDispatch } from 'redux/store';

import { AgentNavbar, MobileNavigationBar } from 'components/shared/Navbar';
import Spinner from 'components/shared/Spinner/Spinner';
import { AgentProfileFormSteps, mobileScreenSize } from 'constants/agentConstants';
import { checkFormPage, checkProfilePage, isApplicantResume, urlMatcher } from 'helpers/agentHelper';
import { acceptAgentOrganizationInviteHanlder } from 'helpers/organizationHelper';
import { Size, useWindowSize } from 'hooks/useResize';
import { errorStatusDetails } from 'shared/constants';
import { Notification, NotificationType } from 'shared/Notification/Notification';
import { routes } from 'shared/routes';
import { AgentUserRoles } from 'shared/types/agentInvite';
import { AgentInitialProfileInformation } from 'shared/types/agentTypes';
import { UserType } from 'shared/types/authType';

import styles from './agentLayout.module.scss';

const AgentLayout = (): JSX.Element => {
  // HOOKS
  const { t } = useTranslation();
  const dispatch = useDispatch<AppThunkDispatch>();
  const isInviteAccepted = useRef(false);
  const location = useLocation();
  const navigate = useNavigate();
  const size: Size = useWindowSize();
  // SELECTORS
  const isPageLoading = useSelector(isLoading);
  const serverError = useSelector(serverErrorAuth);
  const inviteCode = useSelector(selectInviteCode);
  const agentProfileInformation = useSelector(selectAgentProfileInformation);
  const authUserType = useSelector(userType);
  const currentOrganization = useSelector(selectedOrganization);
  // VARIABLES
  const isProfilePage = checkProfilePage(location);
  const isFormPage = checkFormPage(location);
  const isResumePage = isApplicantResume(location);
  // MEMOIZED VALUES
  const shouldShowMobileNavBar = useMemo((): boolean => size.width <= mobileScreenSize, [size]);
  const shouldNotShowNavbar = useMemo(
    (): boolean => urlMatcher('renter-resume', location) && shouldShowMobileNavBar,
    [location, shouldShowMobileNavBar]
  );
  const isExtraMargin = useMemo(
    (): boolean =>
      (urlMatcher(routes.properties, location) || urlMatcher(routes.applicants, location)) &&
      !location?.pathname?.includes('/properties/'),
    [location]
  );
  const isOwner = useMemo(
    () => agentProfileInformation?.agentProfessionalProfile?.role === AgentUserRoles.OWNER,
    [agentProfileInformation]
  );
  const isNewUser = useMemo(() => !agentProfileInformation?.agentProfessionalProfile?.role, [agentProfileInformation]);
  const hasIncompleteBusinessProfile = useMemo(() => {
    const isUnverifiedOrg = currentOrganization.id && !currentOrganization.experianVerified;
    const hasNoExperianReg = !agentProfileInformation.experianRegistration;
    const hasNoAgentId = !agentProfileInformation.agentId;

    return (isUnverifiedOrg && hasNoExperianReg) || (hasNoAgentId && hasNoExperianReg);
  }, [currentOrganization, agentProfileInformation]);
  const hasIncompleteProfessionalProfile = useMemo(() => !agentProfileInformation.firstName, [agentProfileInformation]);
  const isNonProfilePage = useMemo((): boolean => !isFormPage && !isProfilePage, [isFormPage, isProfilePage]);
  const isSuperAdmin = useMemo(
    (): boolean => agentProfileInformation.agentProfessionalProfile?.role === UserType.SUPERADMIN,
    [agentProfileInformation.agentProfessionalProfile]
  );
  const isOrgDashboard = useMemo((): boolean => urlMatcher(routes.organizations, location), [location]);

  useEffect(() => {
    if (authUserType === UserType.AGENT) {
      dispatch(getAgentProfileInformation());
    }
  }, [authUserType, dispatch]);

  useEffect(() => {
    if (isSuperAdmin && !isOrgDashboard) {
      navigate(routes.organizations);
    } else if (AgentInitialProfileInformation !== agentProfileInformation && !isProfilePage && !isFormPage) {
      if (isNewUser) {
        navigate(routes.agentProfileForm);
      } else if (isOwner && hasIncompleteBusinessProfile) {
        Notification({ message: t('agent.redirection.errorMessage'), type: NotificationType.INFO });
        navigate(routes.agentProfileForm);
      } else if (!isOwner && hasIncompleteProfessionalProfile) {
        navigate(routes.generateAgentProfileForm(AgentProfileFormSteps.INVITED));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    agentProfileInformation,
    currentOrganization.experianVerified,
    currentOrganization.id,
    dispatch,
    hasIncompleteBusinessProfile,
    hasIncompleteProfessionalProfile,
    isFormPage,
    isNewUser,
    isOwner,
    isProfilePage,
    t,
  ]);

  useEffect(() => {
    if (
      inviteCode &&
      authUserType === UserType.AGENT &&
      // !isProfilePage &&
      // agentProfileInformation?.agentId
      // &&
      !isInviteAccepted.current &&
      (currentOrganization.experianVerified ||
        agentProfileInformation.experianRegistration ||
        currentOrganization.roleName !== AgentUserRoles.OWNER)
    ) {
      acceptAgentOrganizationInviteHanlder({ inviteCode, dispatch, navigate });
      isInviteAccepted.current = true;
    }

    !isProfilePage &&
      agentProfileInformation.agentId &&
      agentProfileInformation.lastOrganizationVisitedId &&
      dispatch(getAgentOrganizations());
  }, [
    agentProfileInformation,
    authUserType,
    currentOrganization.experianVerified,
    currentOrganization.roleName,
    dispatch,
    inviteCode,
    isProfilePage,
    navigate,
  ]);

  useEffect(() => {
    if (serverError && !!errorStatusDetails[serverError]) {
      navigate(errorStatusDetails[serverError].route);
      dispatch(setServerError(0));
    }
  }, [dispatch, navigate, serverError]);

  if (isPageLoading) {
    return <Spinner />;
  }

  return (
    <div className="main">
      {!shouldNotShowNavbar && (
        <div className={classNames(styles.topNavbar, { [styles.extraMarginDiv]: isExtraMargin })}>
          <AgentNavbar shouldShowMobileNavBar={shouldShowMobileNavBar} isSuperAdmin={isOrgDashboard} />
        </div>
      )}
      <div
        className={classNames(styles.outletDiv, {
          [styles.extraHeightDiv]: isExtraMargin,
          [styles.BottomNavbar]: !isProfilePage && isNonProfilePage,
          [styles.ResumeOutletDiv]: shouldNotShowNavbar,
          [styles.NoScroll]: isResumePage,
        })}
      >
        <Outlet />
      </div>
      {isNonProfilePage && shouldShowMobileNavBar && !isProfilePage && !shouldNotShowNavbar && (
        <div className={styles.mobileNavBarDiv}>
          <MobileNavigationBar />
        </div>
      )}
    </div>
  );
};

export default AgentLayout;
